/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import moment from 'moment';
import { find, filter, isEmpty, reduce } from 'lodash';

let codeIdMapper;
const ATTRIBUTE_CODES = {
  AUTO_APPROVE: 'autoApprove',
  DEPENDENT_ORGUNITGROUP_ID: 'dependentOrgUnitGroupId',
  DISABLED_CODE: 'isDisabled',
  ESTIMATED_TARGET_POPULATION_CODE: 'estimatedTargetPopulation',
  EST_POPULATION_LESS_THAN_1_YEAR_CODE: 'estPopulationLessThan1Year',
  EST_POPULATION_BETWEEN_1_AND_5_YEARS_CODE: 'estPopulationBetween1And5Years',
  EST_POPULATION_OF_WOMEN_OF_CHILD_BEARING_AGE_CODE:
    'estPopulationOfWomenOfChildBearingAge',
  LINE_LIST_OFFLINE_SUMMARY_CODE: 'praxisLineListSummaryType',
  REFERRED_TO_DATA_SET_CODE: 'isReferredToDataset',
  LINE_LIST_ATTRIBUTE_CODE: 'isLineListService',
  NEW_DATA_MODEL_CODE: 'isNewDataModel',
  ORIGIN_DATA_SET_CODE: 'isOriginDataset',
  ORG_UNIT_GROUP_SET_LEVEL: 'groupSetLevel',
  ORG_UNIT_GROUP_SET_MANDATORY: 'isOrgUnitGroupSetMandatory',
  POPULATION_DATA_SET_CODE: 'isPopulationDataset',
  PROJECT_CODE: 'projCode',
  PROJECT_END_DATE_CODE: 'prjEndDate',
  PROJECT_LOCATION_CODE: 'prjLoc',
  REFERRED_FROM_DATA_SET_CODE: 'isReferredFromDataset',
  REFERRED_TO_OPTION_SET_CODE: 'isReferredToOptionSet',
  REFERRED_FROM_OPTION_SET_CODE: 'isReferredFromOptionSet',
  SERVICE_CODE: 'praxisServiceCode',
  SHOW_IN_EVENT_SUMMARY_CODE: 'showInEventSummary',
  TYPE: 'Type',
  PRAXIS_POPULATION_DATA_ELEMENTS: 'praxisPopulationDataElements',
  HIDE_AGGREGATE_DATA_SET_SECTION_TOTALS: 'hideAggregateDataSetSectionTotals',
  MANDATORY_CODE: 'mandatory',
  PRAXIS_SHOW_IN_MODULE_CREATION_CODE: 'praxisShowInModuleCreation',
  MULTI_STAGE_LINE_LIST_ATTRIBUTE_CODE: 'isMultiStageLineListService',
  ASSOCIATED_DATA_SET_CODE: 'associatedDataSet',
  EXCLUDE_FROM_TOTAL: 'praxisExcludeFromTotal',
  HAS_AGE_IN_MONTHS_FIELD_CODE: 'hasAgeInMonthsField',
  EVENT_DATE: 'useAsEventDate',
  PEDIATRIC_AGE_FIELD_CODE: 'pediatricAgeField',
  IS_PATIENT_INITIALS_FIELD: 'isPatientInitialsField',
  IS_DATA_BEYOND_ONE_YEAR: 'isDataBeyondOneYear',
};

const getAttributeValue = (attributeValues, attributeCode, defaultValue) => {
  const correspondingAttributeValue = find(attributeValues, {
    attribute: {
      code: attributeCode,
    },
  });

  return correspondingAttributeValue
    ? correspondingAttributeValue.value
    : defaultValue;
};

const getBooleanAttributeValue = (attributeValues, attributeCode) =>
  getAttributeValue(attributeValues, attributeCode) === 'true';

const cleanAttributeValues = (attributeValues) =>
  filter(
    attributeValues,
    (attributeValue) => attributeValue && !isEmpty(attributeValue.value)
  );

const createAttribute = (attributeCode, value) => ({
  created: moment().toISOString(),
  lastUpdated: moment().toISOString(),
  attribute: {
    code: attributeCode,
    id: codeIdMapper[attributeCode],
  },
  value,
});

const initializeCustomAttributes = (attributes) => {
  codeIdMapper = reduce(
    attributes,
    (result, attribute) => {
      result[attribute.code] = attribute.id;
      return result;
    },
    {}
  );
};

export default {
  ...ATTRIBUTE_CODES,
  getBooleanAttributeValue,
  getAttributeValue,
  cleanAttributeValues,
  createAttribute,
  initializeCustomAttributes,
};
