import JSZip from 'jszip';
import _ from 'lodash';
import moment from 'moment';

const zipData = async (folderName, fileNamePrefix, fileNameExtension, data) => {
  const zip = new JSZip();
  const keys = _.keys(data);
  _.forEach(keys, (key) => {
    const fileName = fileNamePrefix + key + fileNameExtension;
    const fileData = {};
    fileData[key] = data[key];
    zip.folder(folderName).file(fileName, JSON.stringify(fileData));
  });
  const contents = await zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 6,
    },
  });
  return contents;
};

const createZip = async (
  folderName,
  fileNamePrefix,
  fileNameExtension,
  zipFileExtension,
  data
) => {
  const zippedData = await zipData(
    folderName,
    fileNamePrefix,
    fileNameExtension,
    data
  );
  const fileName =
    fileNamePrefix + moment().format('YYYYMMDD-HHmmss') + zipFileExtension;
  const zipFile = {
    zipFileName: fileName,
    zipFileData: zippedData,
  };
  return zipFile;
};

const readZipFile = async (file) => {
  const zip = new JSZip();
  const zipObject = await zip.loadAsync(file);
  return zipObject.files;
};

export { createZip, readZipFile };
