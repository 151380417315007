/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { APP_EVENTS, NAVIGATION_ROUTES } from '../constants/Constants';
import { isProductKeySet } from '../repositories/system.setting.repository';
import { checkMetadata } from '../helpers/metadata.helper';
import { sendMessage } from '../utils/pwa.utils';
import customAttributes from '../models/custom.attributes';
import getAllCustomAttributes from '../repositories/custom.attributes.repository';

const ResolveRoute = ({ children }) => {
  const [noProductKey, setNoProductKey] = useState(undefined);
  const [noMetadata, setNoMetadata] = useState(undefined);

  const initializeCustomAttributesList = async () => {
    const attributesList = await getAllCustomAttributes();
    customAttributes.initializeCustomAttributes(attributesList);
  };

  const init = async () => {
    const productKey = await isProductKeySet();
    const metadata = await checkMetadata();

    if (!productKey) {
      setNoProductKey(true);
    } else if (!metadata) {
      setNoMetadata(true);
    } else {
      setNoProductKey(false);
      setNoMetadata(false);
      await initializeCustomAttributesList();
    }
  };

  useEffect(async () => {
    const productKey = await isProductKeySet();
    const metadata = await checkMetadata();
    if (productKey && metadata) {
      sendMessage(APP_EVENTS.START_BG_APP);
    }
  }, []);

  useEffect(() => {
    init();
  }, [children]);

  return (
    <>
      {noProductKey && (
        <Navigate to={`/${NAVIGATION_ROUTES.UPDATE_PRODUCT_KEY}`} replace />
      )}
      {noMetadata && (
        <Navigate to={`/${NAVIGATION_ROUTES.DOWNLOAD_METADATA}`} replace />
      )}
      {noProductKey !== undefined && noMetadata !== undefined && children}
    </>
  );
};

ResolveRoute.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ResolveRoute;
