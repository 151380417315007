/* eslint-disable no-param-reassign */
import { some, includes, reduce, map, isArray } from 'lodash';
import { ROLE } from '../constants/Constants';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
  });
};

export const hasRoles = (allowedRoles, currentUser) =>
  currentUser === undefined
    ? false
    : some(currentUser.userCredentials.userRoles, (userAuth) =>
        includes(allowedRoles, userAuth.name)
      );

export const promisifyValue = (value) =>
  new Promise((resolve) => {
    resolve(value);
  });

export const rejectPromisifyValue = (value) =>
  new Promise((resolve, reject) => {
    reject(value);
  });

export const roundToDecimal = (number) => {
  if (Number.isNaN(parseInt(number, 10))) {
    return '';
  }
  const multiplicator = 10 ** 2;
  const decimalNumber = parseFloat((number * multiplicator).toFixed(2));
  return Math.round(decimalNumber) / multiplicator;
};

export const totalSum = (section, rowTotal) => {
  const dataElementsIds = map(section.dataElements, 'id');
  return reduce(
    rowTotal,
    (sum, value, key) => {
      if (includes(dataElementsIds, key)) {
        return roundToDecimal(sum + value);
      }
      return roundToDecimal(sum);
    },
    0
  );
};

export const calculateSum = (cellValue, existingValue) => {
  if (!cellValue) {
    return existingValue ? 0 : '';
  }

  cellValue = cellValue
    .toString()
    .split('+')
    .filter((e) => e);
  return reduce(cellValue, (sum, exp) => sum + parseFloat(exp), 0);
};

export const isProjectLevelApprover = (userContext) =>
  userContext === undefined
    ? false
    : hasRoles([ROLE.PROJECT_LEVEL_APPROVER], userContext.currentUser);

export const isCoordinationLevelApprover = (userContext) =>
  userContext === undefined
    ? false
    : hasRoles([ROLE.COORDINATION_LEVEL_APPROVER], userContext.currentUser);

export const isDataEntryUser = (userContext) =>
  userContext === undefined
    ? false
    : hasRoles([ROLE.DATA_ENTRY_USER], userContext.currentUser);

export const isObserver = (userContext) =>
  userContext === undefined
    ? false
    : hasRoles([ROLE.OBSERVER], userContext.currentUser);

export const isApprovalUser = (userContext) =>
  userContext === undefined
    ? false
    : hasRoles(
        [
          ROLE.OBSERVER,
          ROLE.PROJECT_LEVEL_APPROVER,
          ROLE.COORDINATION_LEVEL_APPROVER,
        ],
        userContext.currentUser
      );

export const createRequestParams = (config) => {
  const params = new URLSearchParams();
  Object.keys(config).forEach((key) => {
    if (isArray(config[key])) {
      map(config[key], (param) => {
        params.append(key, param);
      });
    } else if (config[key] !== undefined) {
      params.append(key, config[key]);
    }
  });
  return params;
};
