import { isEmpty, isUndefined } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import properties from '../../../conf/properties';
import { getTranslatedMessage } from '../../../services/translation.service';
import { useLocaleContext } from '../../context/localeContext';
import { useProductKeyContext } from '../../context/productKeyContext';

const AppFooter = () => {
  const { layoutDirection } = useLocaleContext();
  const { productKeyDetails } = useProductKeyContext();
  const [dhisUrl, setDhisUrl] = useState('');
  const intl = useIntl();

  useEffect(() => {
    if (
      properties.devMode &&
      (isUndefined(productKeyDetails?.dhisUrl) ||
        isEmpty(productKeyDetails?.dhisUrl))
    )
      setDhisUrl(properties.dhis.url);
    else setDhisUrl(productKeyDetails.dhisUrl);
  }, [productKeyDetails]);

  return (
    <footer>
      <div
        className={`container small text-muted hide-from-print ${
          layoutDirection !== 'rtl' ? 'text-right' : 'rtl text-left'
        }`}
      >
        <p>
          {!isEmpty(dhisUrl) &&
            getTranslatedMessage(
              intl,
              'connectionMessage',
              'Connected to {dhis_url}',
              { dhis_url: dhisUrl }
            )}
        </p>
      </div>
    </footer>
  );
};

export default AppFooter;
