/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faCaretRight,
  faCaretLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { keys, map } from 'lodash';
import {
  APP_EVENTS,
  NAVIGATION_ROUTES,
  ROLE,
} from '../../../constants/Constants';
import { logout, saveSessionState } from '../../../helpers/session.helper';
import logo from '../../../img/logo.png';
import logoTest from '../../../img/logo-test.png';
import { clearStorage } from '../../../services/storage.service';
import { useLocaleContext } from '../../context/localeContext';
import { useProductKeyContext } from '../../context/productKeyContext';
import { useUserContext } from '../../context/userContext';
import properties from '../../../conf/properties';
import { hasPoorConnectivity } from '../../../monitors/dhis.monitor';
import { hasRoles } from '../../../utils/helper.utils';
import {
  isKeyGeneratedFromProd,
  isSyncOff,
  upsertSyncSetting,
} from '../../../repositories/system.setting.repository';
import { getTranslatedMessage } from '../../../services/translation.service';
import ConfirmDialog from '../confirm-dialog/ConfirmDialog';
import {
  createNotification,
  sendMessage,
  uninstall,
} from '../../../utils/pwa.utils';
import { useLoadingContext } from '../../context/loadingContext';
import Loading from '../loading/Loading';
import DescriptionPopup from '../description-popup/DescriptionPopup';
import metadataConf from '../../../conf/metadata.conf';
import { clearChangeLog } from '../../../repositories/changeLog.repository';
import {
  publishDownloadProjectData,
  publishDownloadMetaData,
} from '../../../helpers/downloadData.helper';
import ExportApplication from '../../../components/exports/ExportApplication';
import ImportApplication from '../../../components/imports/ImportApplication';
import ExportLogs from '../../../components/exports/ExportLogs';
import './Header.less';
import useOutsideClick from '../hooks/useOutsideClick';
import HeaderMenu from '../../../components/header-menu/HeaderMenu';

const AppHeader = () => {
  const { loading, message } = useLoadingContext();

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const { locale, layoutDirection } = useLocaleContext();
  const { currentUser, updateCurrentUser } = useUserContext();
  const { productKeyDetails } = useProductKeyContext();
  const [isOffline, setIsOffline] = useState(false);
  const [isDhisOnline, setIsDhisOnline] = useState(false);
  const [poorConnection, setPoorConnection] = useState(false);
  const [remainingJobs, setRemainingJobs] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [openTurnOnSync, setOpenTurnOnSync] = useState(false);
  const [openTurnOffSync, setOpenTurnOffSync] = useState(false);
  const [showForceDownloadModal, setShowForceDownloadModal] = useState(false);
  const [showExportAppConfirmationModel, setShowExportAppConfirmationModel] =
    useState(false);
  const [showExportLogsConfirmationModel, setShowExportLogsConfirmationModel] =
    useState(false);
  const [showImportAppConfirmationModel, setShowImportAppConfirmationModel] =
    useState(false);
  const hamburgerRef = useRef();
  const { outsideClickEvent } = useOutsideClick(hamburgerRef);
  const [
    showForceDownloadProjectDataModal,
    setShowForceDownloadProjectDataModal,
  ] = useState(false);
  const confirmationMessageRef = useRef({
    title: '',
    message: '',
  });
  const helpRef = useRef({
    title: '',
    description: '',
  });
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showUninstallPraxisModal, setShowUninstallPraxisModal] =
    useState(false);
  const exportApplicationRef = useRef();
  const importApplicationRef = useRef();

  const getVersionNumber = () => properties.praxis.version;

  const clearSession = async () => {
    clearStorage();
    await saveSessionState(currentUser);
  };

  const handleLogout = async () => {
    const user = { ...currentUser, isLoggedIn: false };
    updateCurrentUser(user);
    await logout();
  };

  const hideSyncStatus = () =>
    location.pathname === `/${NAVIGATION_ROUTES.DOWNLOAD_METADATA}` ||
    location.pathname === `/${NAVIGATION_ROUTES.UPDATE_PRODUCT_KEY}`;

  const getRemainingJobs = () => {
    const resourceBundleKey = remainingJobs === 1 ? 'job' : 'jobs';
    return `${remainingJobs} ${getTranslatedMessage(
      intl,
      resourceBundleKey,
      'Jobs'
    )}`;
  };
  const poorConnectionMsg = getTranslatedMessage(
    intl,
    'poorConnection',
    'poor connection'
  );
  const noPendingRequests = getTranslatedMessage(
    intl,
    'noPendingRequest',
    'no pending requests'
  );
  const pendingRequests = getTranslatedMessage(
    intl,
    'pendingRequests',
    'pending requests'
  );
  const onlineMsg = getTranslatedMessage(intl, 'online', 'online');
  const offlineMsg = getTranslatedMessage(intl, 'offline', 'offline');

  const checkConnectionQuality = () => {
    setPoorConnection(hasPoorConnectivity());
    setTimeout(checkConnectionQuality, 5000);
  };

  const initialize = async () => {
    const offline = await isSyncOff();
    setIsOffline(offline);
    await checkConnectionQuality();
  };

  useEffect(() => {
    initialize();
  }, []);

  const gotoProductKeyPage = (event) => {
    setShowMenu(!showMenu);
    event.preventDefault();
    navigate(`/${NAVIGATION_ROUTES.UPDATE_PRODUCT_KEY}`, {
      replace: true,
    });
  };

  const turnOnSync = async () => {
    setIsOffline(false);
    await upsertSyncSetting(false);
    sendMessage(APP_EVENTS.START_BG_APP);
    setOpenTurnOnSync(false);
  };

  const turnOffSync = async () => {
    setIsOffline(true);
    await upsertSyncSetting(true);
    sendMessage(APP_EVENTS.STOP_BG_APP);
    setOpenTurnOffSync(false);
  };

  const toggleSync = (event) => {
    event.preventDefault();
    if (isOffline) {
      confirmationMessageRef.current = {
        title: getTranslatedMessage(intl, 'sync.turnOn', ''),
        message: getTranslatedMessage(
          intl,
          'sync.turnOnConfirmationMessage',
          ''
        ),
      };
      setOpenTurnOnSync(true);
    } else {
      confirmationMessageRef.current = {
        title: getTranslatedMessage(intl, 'sync.turnOff', ''),
        message: getTranslatedMessage(
          intl,
          'sync.turnOffConfirmationMessage',
          ''
        ),
      };
      setOpenTurnOffSync(true);
    }
  };

  const clearProjectDataChangeLog = async () => {
    const projectDataTypes = [
      'dataValues:',
      'monthlyChartData:',
      'monthlyEventReportData:',
      'monthlyPivotTableData:',
      'weeklyChartData:',
      'weeklyEventReportData:',
      'weeklyPivotTableData:',
      'yearlyChartData:',
      'yearlyEventReportData:',
      'yearlyPivotTableData:',
      'yearlyDataValues:',
    ];
    const clearChangeLogPromises = projectDataTypes.map((projectDataType) =>
      clearChangeLog(projectDataType)
    );
    return Promise.all(clearChangeLogPromises);
  };

  const clearMetadataChangeLog = async () => {
    let metadataTypes = keys(metadataConf.fields);
    metadataTypes = metadataTypes.concat([
      'pivotTables',
      'charts',
      'eventReports',
      'projectSettings:',
    ]);
    const clearChangeLogPromises = map(metadataTypes, (metadataType) =>
      clearChangeLog(metadataType)
    );
    return Promise.all(clearChangeLogPromises);
  };

  const downloadMetadata = async () => {
    await clearSession();
    navigate(`/${NAVIGATION_ROUTES.DOWNLOAD_METADATA}`);
    sendMessage(APP_EVENTS.STOP_BG_APP);
    await handleLogout();
  };

  const toggleForceDownload = async (event) => {
    event.preventDefault();
    setShowMenu(!showMenu);
    confirmationMessageRef.current = {
      okLabel: 'forceDownloadMetadata.okMessage',
      title: getTranslatedMessage(intl, 'forceDownloadMetadata.title', ''),
      message: getTranslatedMessage(
        intl,
        'forceDownloadMetadata.confirmationMessage',
        ''
      ),
    };
    setShowForceDownloadModal(true);
  };

  const uninstallPraxis = () => {
    confirmationMessageRef.current = {
      ok: 'uninstall.okMessage',
      title: getTranslatedMessage(intl, 'uninstall.title', 'Uninstall Praxis'),
      message: getTranslatedMessage(
        intl,
        'uninstall.confirmationMessage',
        'WARNING: This will erase all the existing data and uninstall the application. ' +
          'Any data not synced to DHIS will be lost. THIS ACTION IS IRREVERSIBLE.'
      ),
    };
    setShowUninstallPraxisModal(true);
  };
  const syncNow = async (event) => {
    event.preventDefault();
    setShowMenu(!showMenu);
    await publishDownloadMetaData(locale);
    await publishDownloadProjectData(locale);
    createNotification(
      getTranslatedMessage(
        intl,
        'downloadDataFromDhis',
        'Download data from DHIS'
      ),
      getTranslatedMessage(
        intl,
        'syncScheduled',
        'Data download scheduled successfully.'
      )
    );
  };

  const changeProject = (event) => {
    event.preventDefault();
    navigate(`/${NAVIGATION_ROUTES.SELECT_PROJECT_PREFERENCE}`);
  };

  const forceDownloadProjectData = (event) => {
    event.preventDefault();
    setShowMenu(!showMenu);
    confirmationMessageRef.current = {
      okLabel: 'forceDownloadProjectData.okMessage',
      title: getTranslatedMessage(intl, 'forceDownloadProjectData.title', ''),
      message: getTranslatedMessage(
        intl,
        'forceDownloadProjectData.confirmationMessage',
        ''
      ),
    };
    setShowForceDownloadProjectDataModal(true);
  };

  const showForceDownloadMetadata = () => !isOffline;
  const canShowForceDownloadProjectdata = () =>
    !hasRoles([ROLE.SUPER_ADMIN, ROLE.PROJECT_ADMIN], currentUser) &&
    !isOffline;

  const updateConfirmationMessage = () => {
    confirmationMessageRef.current = {
      title: '',
      message: '',
    };
  };

  const handleTurnOnSyncCancel = () => {
    updateConfirmationMessage();
    setOpenTurnOnSync(false);
  };

  const handleTurnOffSyncCancel = () => {
    updateConfirmationMessage();
    setOpenTurnOffSync(false);
  };

  const handleForceDownloadMetadataCancel = () => {
    updateConfirmationMessage();
    setShowForceDownloadModal(false);
  };

  const handleForceDownloadMetadata = async () => {
    await clearMetadataChangeLog();
    setShowForceDownloadModal(false);
    await downloadMetadata();
  };

  const handleUninstallPraxis = async () => {
    await uninstall();
    document.getElementById('praxisRoot').style.display = 'block';
    document.getElementById('praxisRoot').style.color = 'white';
    document.getElementById('praxisRoot').style.margin = '150px 550px 0';
    document.getElementById('praxisRoot').style.fontWeight = 'bold';
    document.getElementById('praxisRoot').style.textAlign = 'center';
    document.getElementById('praxisRoot').innerHTML = getTranslatedMessage(
      intl,
      'uninstall.successMessage',
      'Uninstall Praxis'
    );
    setShowUninstallPraxisModal(false);
  };

  const handleUninstallPraxisCancel = () => {
    setShowUninstallPraxisModal(false);
  };

  const handleForceDownloadProjectDataCancel = () => {
    updateConfirmationMessage();
    setShowForceDownloadProjectDataModal(false);
  };

  const handleForceDownloadProjectData = async () => {
    await clearProjectDataChangeLog();
    await publishDownloadProjectData(locale);
    setShowForceDownloadProjectDataModal(false);
  };

  const isProductKeyPage = () =>
    location.pathname === `/${NAVIGATION_ROUTES.UPDATE_PRODUCT_KEY}`;

  const handleHelp = (event) => {
    event.preventDefault();
    helpRef.current = {
      title: getTranslatedMessage(intl, 'help', 'Help?'),
      description: intl.formatMessage(
        { id: 'contactSupport' },
        { supportEmail: properties.support_email }
      ),
    };
    setShowHelpModal(true);
  };

  const handleImportApplication = () => {
    setShowImportAppConfirmationModel(false);
    importApplicationRef.current.handleClick();
  };

  const handleExportApplication = () => {
    setShowExportAppConfirmationModel(false);
    exportApplicationRef.current.handleExportApplication();
  };

  useEffect(() => {
    if (outsideClickEvent) {
      setShowMenu(false);
    }
  }, [outsideClickEvent]);

  useEffect(() => {
    const bc = new BroadcastChannel(APP_EVENTS.QUEUE_SYNC_CHANGE);
    bc.addEventListener('message', async (event) => {
      const { data: payload } = event;
      const remJobs = payload ? payload.count + payload.reservedCount : 0;
      setRemainingJobs(remJobs);
    });

    self.worker.onmessage = (e) => {
      const { name: eventName } = e.data;
      if (eventName === APP_EVENTS.DHIS_ONLINE) setIsDhisOnline(true);
      if (eventName === APP_EVENTS.DHIS_OFFLINE) setIsDhisOnline(false);
    };
  }, []);

  const showTestLogo = () => !isKeyGeneratedFromProd();

  return (
    <>
      <Loading loading={loading} message={message} />
      <header>
        <div className="container">
          <nav className="navbar">
            <div className={layoutDirection}>
              <div
                className={`navbar-header ${
                  layoutDirection === 'rtl' ? 'navbar-right' : ''
                }`}
              >
                <img
                  src={
                    productKeyDetails &&
                    productKeyDetails.isKeyGeneratedFromProd
                      ? logo
                      : logoTest
                  }
                  alt=""
                  title="PRAXIS"
                  id="logoImg"
                  className="navbar-brand logo"
                  tabIndex="-1"
                />
                <label className="version-number">{getVersionNumber()}</label>
              </div>
              {!isProductKeyPage() && showTestLogo() && (
                <div className="label label-danger training-msg-label">
                  <FormattedMessage
                    id="praxisTrainingVersionMessage"
                    defaultMessage="PRAXIS TRAINING VERSION"
                  />
                </div>
              )}
              <div
                className={`nav navbar-nav ${
                  layoutDirection !== 'rtl' ? 'navbar-right' : ''
                } `}
              >
                <p className="text-right login-message">
                  {currentUser.isLoggedIn && (
                    <strong>
                      {currentUser.userCredentials.username}
                      {' | '}
                    </strong>
                  )}
                  <span>
                    <a href="" onClick={handleHelp}>
                      <FormattedMessage id="help" defaultMessage="Help?" />
                    </a>
                  </span>
                  {currentUser.isLoggedIn && (
                    <span>
                      {' | '}
                      <Link
                        data-testid="logout"
                        to={`/${NAVIGATION_ROUTES.LOGIN}`}
                        onClick={handleLogout}
                      >
                        <FormattedMessage
                          id="logoutLabel"
                          defaultMessage="Logout"
                        />
                      </Link>
                    </span>
                  )}
                </p>
                <ul
                  className={`nav navbar-nav ${
                    layoutDirection !== 'rtl' ? 'navbar-right' : ''
                  } `}
                >
                  {remainingJobs >= 0 && !hideSyncStatus() && (
                    <li className="sync-status">
                      {isDhisOnline && !poorConnection && remainingJobs > 0 && (
                        <span
                          className="badge dhis-online"
                          title={`${`ONLINE-${getRemainingJobs()}`}`}
                        >
                          {getRemainingJobs()}
                        </span>
                      )}
                      {isDhisOnline && !poorConnection && remainingJobs === 0 && (
                        <span
                          className="badge dhis-online"
                          title={`${onlineMsg} ${noPendingRequests}`}
                        >
                          {getRemainingJobs()}
                        </span>
                      )}
                      {poorConnection && remainingJobs > 0 && (
                        <span
                          className="badge dhis-poor-connection"
                          title={`${poorConnectionMsg} ${getRemainingJobs()} ${pendingRequests}`}
                        >
                          {getRemainingJobs()}
                        </span>
                      )}
                      {poorConnection && remainingJobs === 0 && (
                        <span
                          className="badge dhis-poor-connection"
                          title={`${poorConnectionMsg} ${noPendingRequests}`}
                        >
                          {getRemainingJobs()}
                        </span>
                      )}
                      {!isDhisOnline && !poorConnection && remainingJobs > 0 && (
                        <span
                          className="badge dhis-offline"
                          title={`${onlineMsg} ${getRemainingJobs()} ${pendingRequests}`}
                        >
                          {getRemainingJobs()}
                        </span>
                      )}
                      {!isDhisOnline && !poorConnection && remainingJobs === 0 && (
                        <span
                          className="badge dhis-offline"
                          title={`${offlineMsg} ${noPendingRequests}`}
                        >
                          {getRemainingJobs()}
                        </span>
                      )}
                    </li>
                  )}
                  <li>
                    <div>
                      <button
                        ref={(node) => {
                          hamburgerRef.current = node;
                        }}
                        type="button"
                        className="btn btn-link dropdown-toggle hamburger-menu"
                        onClick={() => setShowMenu(!showMenu)}
                      >
                        <FontAwesomeIcon
                          icon={faBars}
                          title={getTranslatedMessage(intl, 'menu', 'Menu')}
                        />
                      </button>
                      {currentUser.isLoggedIn && (
                        <ul
                          className={`dropdown-menu ${showMenu ? 'show' : ''}`}
                          role="menu"
                        >
                          <li>
                            <a
                              href=""
                              onClick={syncNow}
                              tabIndex="-1"
                              data-testid="downloadDataFromDHIS"
                            >
                              <FormattedMessage
                                id="downloadDataFromDhis"
                                defaultMessage="Download data from DHIS"
                              />
                            </a>
                          </li>
                          {hasRoles([ROLE.PROJECT_ADMIN], currentUser) && (
                            <li>
                              <a href="" onClick={changeProject} tabIndex="-1">
                                <FormattedMessage
                                  id="changeProject"
                                  defaultMessage="Change project"
                                />
                              </a>
                            </li>
                          )}
                          <li className="divider" />
                          <li>
                            <a
                              href=""
                              onClick={gotoProductKeyPage}
                              tabIndex="-1"
                            >
                              <FormattedMessage
                                id="updateProductKeyMenuLabel"
                                defaultMessage="Update product key"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="" onClick={toggleSync}>
                              <FormattedMessage
                                id={isOffline ? 'sync.turnOn' : 'sync.turnOff'}
                                defaultMessage={
                                  isOffline ? 'Turn on Sync' : 'Turn off Sync'
                                }
                              />
                            </a>
                          </li>
                          <li
                            className={`dropdown-submenu ${
                              layoutDirection !== 'rtl' ? 'pull-left' : ''
                            }`}
                          >
                            <a
                              href=""
                              className="dropdown-toggle"
                              tabIndex="-1"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            >
                              <FormattedMessage
                                id="importExportMenuLabel"
                                defaultMessage="Import / Export"
                              />
                              <FontAwesomeIcon
                                className={`${
                                  layoutDirection === 'rtl'
                                    ? 'arrow-icon-left'
                                    : 'arrow-icon-right'
                                }`}
                                icon={
                                  layoutDirection === 'rtl'
                                    ? faCaretLeft
                                    : faCaretRight
                                }
                              />
                            </a>
                            <ul className="dropdown-menu" role="menu">
                              <li className="import-export-menu">
                                <a
                                  href=""
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setShowExportAppConfirmationModel(true);
                                  }}
                                >
                                  <FormattedMessage
                                    id="createCloneLabel"
                                    defaultMessage="Export application"
                                  />
                                </a>
                              </li>
                              <li className="import-export-menu">
                                <a
                                  href=""
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setShowImportAppConfirmationModel(true);
                                  }}
                                >
                                  <FormattedMessage
                                    id="loadCloneLabel"
                                    defaultMessage="Import application copy"
                                  />
                                </a>
                              </li>
                              <li className="divider" />
                              <li className="import-export-menu">
                                <a
                                  href=""
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setShowExportLogsConfirmationModel(true);
                                  }}
                                >
                                  <FormattedMessage
                                    id="dumpLogsLabel"
                                    defaultMessage="Export Logs"
                                  />
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="clearfix" />
                          <li className="divider" />
                          {showForceDownloadMetadata() && (
                            <li>
                              <a href="" onClick={toggleForceDownload}>
                                <FormattedMessage
                                  id="forceDownloadMetadata.title"
                                  defaultMessage="Force Download Metadata"
                                />
                              </a>
                            </li>
                          )}

                          {canShowForceDownloadProjectdata() && (
                            <li>
                              <a href="" onClick={forceDownloadProjectData}>
                                <FormattedMessage
                                  id="forceDownloadProjectData.title"
                                  defaultMessage="Force Download Project data"
                                />
                              </a>
                            </li>
                          )}
                          <li className="uninstall">
                            <Link onClick={uninstallPraxis}>
                              {getTranslatedMessage(
                                intl,
                                'uninstall.title',
                                'Uninstall Praxis'
                              )}
                            </Link>
                          </li>
                        </ul>
                      )}
                      {!currentUser.isLoggedIn && (
                        <ul
                          className={`dropdown-menu ${showMenu ? 'show' : ''}`}
                          role="menu"
                        >
                          <li>
                            <a
                              href=""
                              onClick={gotoProductKeyPage}
                              tabIndex="-1"
                            >
                              <FormattedMessage
                                id="updateProductKeyMenuLabel"
                                defaultMessage="Update product key"
                              />
                            </a>
                          </li>
                          {!hideSyncStatus() && (
                            <li>
                              <a href="" onClick={toggleSync}>
                                <FormattedMessage
                                  id={
                                    isOffline ? 'sync.turnOn' : 'sync.turnOff'
                                  }
                                  defaultMessage={
                                    isOffline ? 'Turn on Sync' : 'Turn off Sync'
                                  }
                                />
                              </a>
                            </li>
                          )}
                          {!hideSyncStatus() && <li className="divider" />}
                          {showForceDownloadMetadata() && !hideSyncStatus() && (
                            <li>
                              <a href="" onClick={toggleForceDownload}>
                                <FormattedMessage
                                  id="forceDownloadMetadata.title"
                                  defaultMessage="Force Download Metadata"
                                />
                              </a>
                            </li>
                          )}
                          {!hideSyncStatus() && (
                            <li className="uninstall">
                              <Link onClick={uninstallPraxis}>
                                {getTranslatedMessage(
                                  intl,
                                  'uninstall.title',
                                  'Uninstall Praxis'
                                )}
                              </Link>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {!isProductKeyPage() && (
        <div
          className={`container info-bar hide-from-print ${layoutDirection}`}
        >
          {isOffline && (
            <div className="red-band">
              <FormattedMessage
                id="sync.turnOffMessage"
                defaultMessage="SYNC IS TURNED OFF"
              />
            </div>
          )}
        </div>
      )}
      {currentUser.isLoggedIn && !hideSyncStatus() && <HeaderMenu />}
      {openTurnOnSync && (
        <ConfirmDialog
          modalTitle={confirmationMessageRef.current.title}
          confirmationMessage={confirmationMessageRef.current.message}
          onOk={turnOnSync}
          onCancel={handleTurnOnSyncCancel}
        />
      )}
      {openTurnOffSync && (
        <ConfirmDialog
          modalTitle={confirmationMessageRef.current.title}
          confirmationMessage={confirmationMessageRef.current.message}
          onOk={turnOffSync}
          onCancel={handleTurnOffSyncCancel}
        />
      )}
      {showHelpModal && (
        <DescriptionPopup
          modalTitle={helpRef.current.title}
          confirmationMessage={helpRef.current.description}
          onClose={() => setShowHelpModal(false)}
        />
      )}
      {showForceDownloadModal && (
        <ConfirmDialog
          modalTitle={confirmationMessageRef.current.title}
          confirmationMessage={confirmationMessageRef.current.message}
          okLabel={confirmationMessageRef.current.okLabel}
          onOk={handleForceDownloadMetadata}
          onCancel={handleForceDownloadMetadataCancel}
        />
      )}
      {showForceDownloadProjectDataModal && (
        <ConfirmDialog
          modalTitle={confirmationMessageRef.current.title}
          confirmationMessage={confirmationMessageRef.current.message}
          okLabel={confirmationMessageRef.current.okLabel}
          onOk={handleForceDownloadProjectData}
          onCancel={handleForceDownloadProjectDataCancel}
        />
      )}
      {showUninstallPraxisModal && (
        <ConfirmDialog
          modalTitle={confirmationMessageRef.current.title}
          confirmationMessage={confirmationMessageRef.current.message}
          okLabel={confirmationMessageRef.current.ok}
          onOk={handleUninstallPraxis}
          onCancel={handleUninstallPraxisCancel}
        />
      )}
      <ExportApplication ref={exportApplicationRef} />
      <ImportApplication ref={importApplicationRef} />
      {showExportLogsConfirmationModel && (
        <ExportLogs onHide={() => setShowExportLogsConfirmationModel(false)} />
      )}
      {showImportAppConfirmationModel && (
        <ConfirmDialog
          modalTitle={getTranslatedMessage(
            intl,
            'confirmImportTitle',
            'Import Confirmation'
          )}
          confirmationMessage={getTranslatedMessage(
            intl,
            'loadCloneConfirmationMessage',
            'WARNING: Importing application copy will delete all existing data not uploaded to DHIS2. You will be logged out of current session after import. THIS ACTION IS IRREVERSIBLE.'
          )}
          onOk={handleImportApplication}
          okLabel="confirmImport"
          locale={locale}
          showCancelBtn
          onCancel={() => setShowImportAppConfirmationModel(false)}
        />
      )}
      {showExportAppConfirmationModel && (
        <ConfirmDialog
          modalTitle={getTranslatedMessage(
            intl,
            'confirmExportTitle',
            'Export Confirmation'
          )}
          confirmationMessage={getTranslatedMessage(
            intl,
            'createCloneConfirmationMessage',
            'WARNING: Exporting application copy will create a copy of the current application on your computer. To be used for importing application ONLY'
          )}
          onOk={handleExportApplication}
          okLabel="confirmExport"
          locale={locale}
          showCancelBtn
          onCancel={() => setShowExportAppConfirmationModel(false)}
        />
      )}
    </>
  );
};

export default AppHeader;
