/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { createZip } from '../../utils/zip.utils';
import ConfirmDialog from '../../shared/components/confirm-dialog/ConfirmDialog';
import { getTranslatedMessage } from '../../services/translation.service';
import { useLocaleContext } from '../../shared/context/localeContext';
import { getLogDbInstance } from '../../indexedDb';
import { useLoadingContext } from '../../shared/context/loadingContext';

const ExportLogs = ({ onHide }) => {
  const PRAXIS_LOGS_TABLE_NAME = 'logs';
  const [showNotification, setShowNotification] = useState(false);
  const intl = useIntl();
  const notificationMessages = useRef(null);
  const { locale } = useLocaleContext();
  const { showLoading, hideLoading, setLoadingMessage } = useLoadingContext();

  const exportLogs = async () => {
    const indexedDb = await getLogDbInstance();
    let logs = await indexedDb.table(PRAXIS_LOGS_TABLE_NAME).toArray();
    logs = _.map(logs, (log) => {
      const logData = { ...log };
      logData.date = log.datetime.substr(0, 10);
      return logData;
    });
    const groupedLogs = _.groupBy(logs, 'date');
    return groupedLogs;
  };

  const errorCallBack = (error) => {
    hideLoading();
    if (_.isObject(error)) {
      notificationMessages.current = {
        notificationMessage: intl.formatMessage(
          { id: 'dumpLogsErrorMessage' },
          { error: error.message }
        ),
        notificationTitle: getTranslatedMessage(
          intl,
          'errorNotification',
          'Error'
        ),
      };
    }
  };

  const dumbLogs = async () => {
    try {
      showLoading();
      const data = await exportLogs();
      const zipFile = await createZip(
        'logs',
        'logs_dump_',
        '.logs',
        '.logs',
        data
      );
      saveAs(zipFile.zipFileData, zipFile.zipFileName);
      hideLoading();
    } catch (error) {
      errorCallBack(error);
      setShowNotification(true);
    }
    onHide();
  };

  useEffect(() => {
    setLoadingMessage(
      getTranslatedMessage(intl, 'pleaseWaitLabel', 'Please wait...')
    );
    dumbLogs();
  }, []);

  return (
    <>
      {showNotification && (
        <ConfirmDialog
          modalTitle={notificationMessages.current.notificationTitle}
          confirmationMessage={notificationMessages.current.notificationMessage}
          onOk={() => setShowNotification(false)}
          okLabel="okLabel"
          locale={locale}
          showCancelBtn={false}
        />
      )}
    </>
  );
};

ExportLogs.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default ExportLogs;
