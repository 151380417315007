/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import './Button.less';

const Button = ({ label, onClick, variant, className, ...rest }) => (
  <button
    {...rest}
    className={`btn custom-btn-${variant} ${className}`}
    onClick={onClick}
  >
    {label}
  </button>
);

Button.defaultProps = {
  className: '',
  onClick: () => {},
  variant: 'primary',
};

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'default',
    'primary-deu',
    'selected-service-btn',
    'service-btn',
  ]),
};

export default Button;
