/* eslint-disable no-console */
import moment from 'moment';
import properties from '../conf/properties';
import { getLogDbInstance } from '../indexedDb';

const logStoreName = 'logs';
const cleanupOldEntires = async (indexedDB) => {
  const boundKeyRange = IDBKeyRange.upperBound(
    moment().subtract(properties.logging.maxAgeinHours, 'hours').toISOString()
  );
  await indexedDB
    .table(logStoreName)
    .where('datetime')
    .below(boundKeyRange.upper)
    .delete()
    .then(() => {
      console.log('Deleting log entries before ', boundKeyRange.upper);
    })
    .catch((e) => {
      console.log('Could not clean up old entries', e);
    });
};

const configureLogger = async () => {
  const indexedDB = await getLogDbInstance();
  await cleanupOldEntires(indexedDB);
};

export default configureLogger;
