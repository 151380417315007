/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouseChimney,
  faCaretDown,
  faSquareCheck,
  faBell,
  faCaretLeft,
  faCaretRight,
  faChartColumn,
  faDatabase,
  faPencil,
  faGlobeAsia,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
import { map, filter, orderBy, sortBy, isEmpty, find } from 'lodash';
import { getTranslatedMessage } from '../../services/translation.service';
import { hasRoles } from '../../utils/helper.utils';
import { MENU_TABS, NAVIGATION_ROUTES, ROLE } from '../../constants/Constants';
import customAttributes from '../../models/custom.attributes';
import {
  enrichWithParent,
  getAllModulesInOrgUnits,
  getAllOpUnitsInOrgUnits,
} from '../../repositories/orgunit.repository';
import { useLoadingContext } from '../../shared/context/loadingContext';
import useOutsideClick from '../../shared/components/hooks/useOutsideClick';
import { useUserContext } from '../../shared/context/userContext';
import { useLocaleContext } from '../../shared/context/localeContext';
import './HeaderMenu.less';
import { saveSessionState } from '../../helpers/session.helper';

const HeaderMenu = () => {
  const NAME = 'name';
  const intl = useIntl();
  const { showLoading, hideLoading, setLoadingMessage } = useLoadingContext();
  const [allUserModules, setAllUserModules] = useState([]);
  const [allOpUnits, setAllOpUnits] = useState([]);
  const [activeTab, setActiveTab] = useState(MENU_TABS.HOME);
  const nodeRef = useRef();
  const reportsRef = useRef();
  const lineListRef = useRef();
  const { outsideClickEvent: outsideClickOfNodeRef } = useOutsideClick(nodeRef);
  const { outsideClickEvent: outsideClickOfReportsRef } =
    useOutsideClick(reportsRef);
  const { outsideClickEvent: outsideClickOfLineListRef } =
    useOutsideClick(lineListRef);
  const { currentUser, updateCurrentUser } = useUserContext();
  const { locale } = useLocaleContext();
  const navigate = useNavigate();
  const [projects, setProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const isDataEntryUser = hasRoles([ROLE.DATA_ENTRY_USER], currentUser);
  const isObserverUser = hasRoles([ROLE.OBSERVER], currentUser);
  const isPlaUser = hasRoles([ROLE.PROJECT_LEVEL_APPROVER], currentUser);
  const isClaUser = hasRoles([ROLE.COORDINATION_LEVEL_APPROVER], currentUser);
  const isSuperAdmin = hasRoles([ROLE.SUPER_ADMIN], currentUser);
  const isProjectAdmin = hasRoles([ROLE.PROJECT_ADMIN], currentUser);

  const isActive = (viewLocationRegex) => {
    const path = new RegExp(viewLocationRegex);
    return path.test(window.location.pathname);
  };
  const tabCss = (url) =>
    `dropdown praxis-dropdown-menu ${isActive(url) ? 'active' : ''}`;
  const reportTabCss = `dropdown praxis-dropdown-menu ${
    isActive('/reports') ||
    isActive('/projectReport') ||
    isActive('/opUnitReport')
      ? 'active'
      : ''
  }`;

  const handleNavigation = (event, url) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(url, { replace: true });
    setActiveTab(null);
  };

  const loadUserLineListModules = async () => {
    if (currentUser && currentUser.selectedProject) {
      const allModulesForUser = await getAllModulesInOrgUnits(
        currentUser.selectedProject.id
      );
      const modulesEnrichedWithParent = await enrichWithParent(
        allModulesForUser
      );
      const allModules = map(modulesEnrichedWithParent, (module) => ({
        id: module.id,
        name: module.name,
        isLineListService: customAttributes.getBooleanAttributeValue(
          module.attributeValues,
          customAttributes.LINE_LIST_ATTRIBUTE_CODE
        ),
        isMultiStageLineListService: customAttributes.getBooleanAttributeValue(
          module.attributeValues,
          customAttributes.MULTI_STAGE_LINE_LIST_ATTRIBUTE_CODE
        ),
      }));
      setAllUserModules(allModules);
    }
  };

  const getModulesInOpUnit = async (opUnit) => {
    const allModules = await getAllModulesInOrgUnits(opUnit.id, 'Module');
    return {
      name: opUnit.name,
      id: opUnit.id,
      modules: map(allModules, (module) => ({
        id: module.id,
        name: module.name,
        isLineListService: customAttributes.getBooleanAttributeValue(
          module.attributeValues,
          customAttributes.LINE_LIST_ATTRIBUTE_CODE
        ),
        isMultiStageLineListService: customAttributes.getBooleanAttributeValue(
          module.attributeValues,
          customAttributes.MULTI_STAGE_LINE_LIST_ATTRIBUTE_CODE
        ),
      })),
    };
  };

  const loadReportOpunitAndModules = async () => {
    const allOpUnitsForProject = await getAllOpUnitsInOrgUnits(
      currentUser.selectedProject.id
    );
    const allOpUnitsWithModules = await Promise.all(
      map(allOpUnitsForProject, getModulesInOpUnit)
    );
    setAllOpUnits(allOpUnitsWithModules);
  };

  const filterLineListModules = (isLinelist, modules) =>
    isLinelist
      ? filter(modules, { isLineListService: true })
      : filter(modules, { isLineListService: false });

  const renderAllModules = (url) => (
    <ul className="dropdown-menu multi-level" role="menu">
      {allOpUnits &&
        map(
          orderBy(allOpUnits, [(opUnit) => opUnit.name.toLowerCase()], NAME),
          (opUnit) => (
            <li
              className={`dropdown-submenu ${
                locale === 'ar' ? 'pull-left' : ''
              }`}
            >
              <a href="" onClick={(event) => event.preventDefault()}>
                {opUnit.name}
                &nbsp;
                <FontAwesomeIcon
                  icon={locale === 'ar' ? faCaretLeft : faCaretRight}
                  size="xs"
                  className="icon"
                />
              </a>
              <ul className="dropdown-menu max-dropdown-width">
                {opUnit.modules &&
                  map(
                    orderBy(
                      opUnit.modules,
                      [(module) => module.name.toLowerCase()],
                      NAME
                    ),
                    (module) => (
                      <li>
                        <a
                          className="submenu-item"
                          onClick={(event) => {
                            handleNavigation(event, `${url}${module.id}`);
                          }}
                          href=""
                          tabIndex="-1"
                        >
                          {module.name}
                        </a>
                      </li>
                    )
                  )}
              </ul>
            </li>
          )
        )}
    </ul>
  );

  const getLineListModuleUrl = (module) =>
    module.isMultiStageLineListService
      ? `line-list-multi-stage-summary/${module.id}`
      : `line-list-summary/${module.id}`;

  const renderModules = (isLineList) => (
    <ul className="dropdown-menu multi-level" role="menu">
      {allOpUnits &&
        map(
          orderBy(allOpUnits, [(opUnit) => opUnit.name.toLowerCase()], NAME),
          (opUnit) =>
            filterLineListModules(isLineList, opUnit.modules).length > 0 && (
              <li
                className={`dropdown-submenu ${
                  locale === 'ar' ? 'pull-left' : ''
                }`}
              >
                <a href="" onClick={(event) => event.preventDefault()}>
                  {opUnit.name}
                  &nbsp;
                  <FontAwesomeIcon
                    icon={locale === 'ar' ? faCaretLeft : faCaretRight}
                    size="xs"
                    className="icon"
                  />
                </a>
                <ul className="dropdown-menu max-dropdown-width">
                  {opUnit.modules &&
                    filterLineListModules(isLineList, opUnit.modules).length >
                      0 &&
                    map(
                      orderBy(
                        filterLineListModules(isLineList, opUnit.modules),
                        [(module) => module.name.toLowerCase()],
                        NAME
                      ),
                      (module) => (
                        <li>
                          <a
                            className="submenu-item"
                            onClick={(event) => {
                              handleNavigation(
                                event,
                                isLineList
                                  ? getLineListModuleUrl(module)
                                  : `/aggregate-data-entry/${module.id}`
                              );
                            }}
                            href=""
                            tabIndex="-1"
                          >
                            {module.name}
                          </a>
                        </li>
                      )
                    )}
                </ul>
              </li>
            )
        )}
    </ul>
  );

  const loadProjects = () => {
    if (currentUser && currentUser.organisationUnits) {
      const userProjects = sortBy(currentUser.organisationUnits, NAME);
      setProjects(userProjects);
      if (!isEmpty(userProjects) && currentUser.selectedProject) {
        const userProject = find(userProjects, [
          'id',
          currentUser.selectedProject.id,
        ]);
        setSelectedProject(userProject.name);
      }
    }
  };

  const initialise = async () => {
    setLoadingMessage(
      getTranslatedMessage(intl, 'pleaseWaitLabel', 'Please wait...')
    );
    showLoading();
    loadProjects();
    await loadUserLineListModules();
    await loadReportOpunitAndModules();
    setActiveTab(MENU_TABS.HOME);
    hideLoading();
  };

  const getSelectedProject = async (event) => {
    const userSelectedProject = find(projects, [NAME, event.target.value]);
    const updatedCurrentUser = {
      ...currentUser,
      selectedProject: userSelectedProject,
    };
    updateCurrentUser(updatedCurrentUser);
    await saveSessionState(updatedCurrentUser);
    navigate(`/${NAVIGATION_ROUTES.DASHBOARD}`);
  };

  useEffect(() => {
    if (isDataEntryUser) {
      if (
        outsideClickOfNodeRef &&
        outsideClickOfReportsRef &&
        outsideClickOfLineListRef
      ) {
        setActiveTab(null);
      }
    } else if (outsideClickOfNodeRef && outsideClickOfReportsRef) {
      setActiveTab(null);
    }
  }, [
    outsideClickOfNodeRef,
    outsideClickOfReportsRef,
    outsideClickOfLineListRef,
  ]);

  useEffect(async () => {
    if (!isSuperAdmin && !isProjectAdmin) {
      await initialise();
    }
  }, [currentUser]);

  return (
    <div
      className={`container hide-from-print header-menu${
        locale === 'ar' ? ' rtl' : ''
      }`}
    >
      <ul className="nav nav-tabs main-menu">
        {(isDataEntryUser || isObserverUser || isPlaUser || isClaUser) && (
          <li
            role="presentation"
            className={isActive('/dashboard') ? 'active' : ''}
            onClick={() => setActiveTab(MENU_TABS.HOME)}
            id="home-menu"
            data-testid="home-menu"
          >
            <Link to="/dashboard">
              <FontAwesomeIcon icon={faHouseChimney} size="xs" />
              &nbsp; &nbsp;
              {getTranslatedMessage(intl, 'homeMenuLabel', 'Home')}
            </Link>
          </li>
        )}
        {(isPlaUser || isClaUser) && allUserModules.length > 0 && (
          <li
            role="presentation"
            onClick={() => setActiveTab(MENU_TABS.APPROVE_DATA)}
            ref={(node) => {
              nodeRef.current = node;
            }}
            className={
              activeTab === MENU_TABS.APPROVE_DATA
                ? `open ${tabCss('/data-approval')}`
                : `${tabCss('/data-approval')}`
            }
            id="approve-data-menu"
          >
            <a
              href=""
              onClick={(event) => event.preventDefault()}
              className="dropdown-toggle"
              role="button"
            >
              <FontAwesomeIcon icon={faSquareCheck} />
              &nbsp; &nbsp;
              {getTranslatedMessage(intl, 'approveDataLabel', 'Approve Data')}
              &nbsp;
              <FontAwesomeIcon icon={faCaretDown} size="xs" />
            </a>
            {activeTab === MENU_TABS.APPROVE_DATA &&
              renderAllModules('/data-approval/')}
          </li>
        )}
        {isObserverUser && allUserModules.length > 0 && (
          <li
            role="presentation"
            onClick={() => setActiveTab(MENU_TABS.AGGREGATED_DATA)}
            ref={(node) => {
              nodeRef.current = node;
            }}
            className={
              activeTab === MENU_TABS.AGGREGATED_DATA
                ? `open ${tabCss('/data-approval')}`
                : `${tabCss('/data-approval')}`
            }
            id="aggregated-data-menu"
            data-testid="aggregated-data-menu"
          >
            <a
              className="dropdown-toggle"
              role="button"
              href=""
              onClick={(event) => event.preventDefault()}
            >
              <FontAwesomeIcon icon={faDatabase} />
              &nbsp; &nbsp;
              {getTranslatedMessage(
                intl,
                'observerDataLabel',
                'Aggregated Data'
              )}
              &nbsp;
              <FontAwesomeIcon icon={faCaretDown} size="xs" />
            </a>
            {activeTab === MENU_TABS.AGGREGATED_DATA &&
              renderAllModules('/data-approval/')}
          </li>
        )}
        {isDataEntryUser &&
          filterLineListModules(false, allUserModules).length > 0 && (
            <li
              role="presentation"
              onClick={() => setActiveTab(MENU_TABS.AGGREGATE_DATA_ENTRY)}
              ref={(node) => {
                nodeRef.current = node;
              }}
              className={
                activeTab === MENU_TABS.AGGREGATE_DATA_ENTRY
                  ? `open ${tabCss('/aggregate-data-entry')}`
                  : `${tabCss('/aggregate-data-entry')}`
              }
              id="aggregate-data-entry-menu"
              data-testid="aggregate-data-entry-menu"
            >
              <a
                className="dropdown-toggle"
                role="button"
                href=""
                onClick={(event) => event.preventDefault()}
              >
                <FontAwesomeIcon icon={faPencil} size="xs" />
                &nbsp; &nbsp;
                {getTranslatedMessage(
                  intl,
                  'dataEntryLabel',
                  'Aggregate Data Entry'
                )}
                &nbsp;
                <FontAwesomeIcon icon={faCaretDown} size="xs" />
              </a>
              {activeTab === MENU_TABS.AGGREGATE_DATA_ENTRY &&
                renderModules(false)}
            </li>
          )}
        {isDataEntryUser &&
          filterLineListModules(true, allUserModules).length > 0 && (
            <li
              role="presentation"
              onClick={() => setActiveTab(MENU_TABS.LINE_LIST_DATA_ENTRY)}
              ref={(node) => {
                lineListRef.current = node;
              }}
              className={
                activeTab === MENU_TABS.LINE_LIST_DATA_ENTRY
                  ? `open ${tabCss('/line-list')}`
                  : `${tabCss('/line-list')}`
              }
              id="line-list-data-entry-menu"
            >
              <a
                className="dropdown-toggle"
                role="button"
                href=""
                onClick={(event) => event.preventDefault()}
              >
                <FontAwesomeIcon icon={faPencil} size="xs" />
                &nbsp; &nbsp;
                {getTranslatedMessage(
                  intl,
                  'lineListDataEntryMenuLabel',
                  'LineList Data Entry'
                )}
                &nbsp;
                <FontAwesomeIcon icon={faCaretDown} size="xs" />
              </a>
              {activeTab === MENU_TABS.LINE_LIST_DATA_ENTRY &&
                renderModules(true)}
            </li>
          )}
        {(isDataEntryUser || isObserverUser || isPlaUser || isClaUser) &&
          allUserModules.length > 0 && (
            <li
              role="presentation"
              onClick={() => setActiveTab(MENU_TABS.REPORTS)}
              ref={(node) => {
                reportsRef.current = node;
              }}
              className={
                activeTab === MENU_TABS.REPORTS
                  ? `open ${reportTabCss}`
                  : `${reportTabCss}`
              }
              id="reports-menu"
              data-testid="reports-menu"
            >
              <a
                className="dropdown-toggle"
                role="button"
                href=""
                onClick={(event) => event.preventDefault()}
              >
                <FontAwesomeIcon icon={faChartColumn} />
                &nbsp; &nbsp;
                {getTranslatedMessage(intl, 'reportsLabel', 'Reports')}
                &nbsp;
                <FontAwesomeIcon icon={faCaretDown} size="xs" />
              </a>
              {activeTab === MENU_TABS.REPORTS && (
                <ul className="dropdown-menu multi-level" role="menu">
                  {allOpUnits &&
                    map(
                      orderBy(
                        allOpUnits,
                        [(opUnit) => opUnit.name.toLowerCase()],
                        NAME
                      ),
                      (opUnit) => (
                        <li
                          className={`dropdown-submenu ${
                            locale === 'ar' ? 'pull-left' : ''
                          }`}
                        >
                          <a
                            href=""
                            onClick={(event) => event.preventDefault()}
                          >
                            {opUnit.name}
                            &nbsp;
                            <FontAwesomeIcon
                              icon={
                                locale === 'ar' ? faCaretLeft : faCaretRight
                              }
                              size="xs"
                              className="icon"
                            />
                          </a>
                          <ul className="dropdown-menu max-dropdown-width">
                            {opUnit.modules &&
                              map(
                                orderBy(
                                  opUnit.modules,
                                  [(module) => module.name.toLowerCase()],
                                  NAME
                                ),
                                (module) => (
                                  <li>
                                    <a
                                      className="submenu-item"
                                      onClick={(event) => {
                                        handleNavigation(
                                          event,
                                          `/reports/${module.id}`
                                        );
                                      }}
                                      href=""
                                      tabIndex="-1"
                                    >
                                      {module.name}
                                    </a>
                                  </li>
                                )
                              )}
                            {opUnit.modules && opUnit.modules.length > 0 && (
                              <li className="divider" />
                            )}
                            <li>
                              <a
                                className="submenu-item"
                                onClick={(event) => {
                                  handleNavigation(
                                    event,
                                    `/opUnitReport/${opUnit.id}`
                                  );
                                }}
                                href=""
                              >
                                {opUnit.name}
                                &nbsp;
                                {getTranslatedMessage(
                                  intl,
                                  'reportLabel',
                                  'Report'
                                )}
                              </a>
                            </li>
                          </ul>
                        </li>
                      )
                    )}
                  <li className="clearfix" />
                  <li className="divider" />
                  <li>
                    <a
                      className="dropdown-toggle"
                      onClick={(event) => {
                        handleNavigation(event, `/projectReport`);
                      }}
                      href=""
                    >
                      {getTranslatedMessage(
                        intl,
                        'projectReportLabel',
                        'Project Report'
                      )}
                    </a>
                  </li>
                </ul>
              )}
            </li>
          )}
        {(isDataEntryUser || isObserverUser || isPlaUser || isClaUser) && (
          <li
            role="presentation"
            className={isActive('/notifications$') ? 'active' : ''}
            id="notifications-menu"
            data-testid="notifications-menu"
          >
            <Link to="/notifications">
              <FontAwesomeIcon icon={faBell} size="1x" />
              &nbsp; &nbsp;
              {getTranslatedMessage(
                intl,
                'notificationsLabel',
                'Notifications'
              )}
            </Link>
          </li>
        )}
        {currentUser.isLoggedIn && isClaUser && (
          <li className={locale === 'ar' ? 'pull-left' : 'pull-right'}>
            {projects && selectedProject && (
              <select
                className="select_project input-sm"
                onChange={getSelectedProject}
                value={selectedProject}
              >
                {map(
                  orderBy(
                    projects,
                    [(project) => project.name.toLowerCase()],
                    NAME
                  ),
                  (project) => (
                    <option key={project.name} value={project.name}>
                      {project.name}
                    </option>
                  )
                )}
              </select>
            )}
          </li>
        )}
        {isSuperAdmin && (
          <li
            role="presentation"
            className={isActive('/org-units$') ? 'active' : ''}
            id="countrySetup-menu"
            data-testid="countrySetup-menu"
          >
            <Link to="/org-units">
              <FontAwesomeIcon icon={faGlobeAsia} />
              &nbsp; &nbsp;
              {getTranslatedMessage(intl, 'countryLabel', 'Country Setup')}
            </Link>
          </li>
        )}
        {isProjectAdmin && (
          <li
            role="presentation"
            className={isActive('/org-units$') ? 'active' : ''}
            id="projectSetup-menu"
            data-testid="projectSetup-menu"
          >
            <Link to="/org-units">
              <FontAwesomeIcon icon={faGear} />
              &nbsp; &nbsp;
              {getTranslatedMessage(intl, 'projectsLabel', 'Project Setup')}
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default HeaderMenu;
