/* eslint-disable import/prefer-default-export */
import { map } from 'lodash';
import { getChangeLog } from '../repositories/changeLog.repository';
import {
  getAllowedOrgUnits,
  getProductKeyLevel,
} from '../repositories/system.setting.repository';

export const checkMetadata = async () => {
  const checkForLastUpdated = async (changeLogKey) => {
    const lastUpdatedTime = await getChangeLog(changeLogKey);
    return !!lastUpdatedTime;
  };

  const metaDataLastUpdatedTime = await checkForLastUpdated('metaData');
  if (!metaDataLastUpdatedTime) return false;

  const productKeyLevel = getProductKeyLevel();
  if (productKeyLevel !== 'global') {
    const allowedOrgUnitChangeLogKeys = map(
      getAllowedOrgUnits() || [],
      (orgUnit) => `organisationUnits:${orgUnit.id}`
    );
    const booleanValues = await Promise.all(
      map(allowedOrgUnitChangeLogKeys, checkForLastUpdated)
    );
    const isModified = booleanValues.some((bool) => bool === false);
    return !isModified;
  }
  return checkForLastUpdated('organisationUnits');
};
