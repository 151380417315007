import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NAVIGATION_ROUTES, ROLE } from '../constants/Constants';
import PrivateRoute from './PrivateRoute';
import ResolveRoute from './ResolveRoute';

import { getItemFromStorage } from '../services/storage.service';
import { doRefresh } from '../helpers/session.helper';
import { useUserContext } from './context/userContext';
import { hasRoles } from '../utils/helper.utils';

const AppRoutes = () => {
  const { currentUser, updateCurrentUser } = useUserContext();

  const setSessionData = async () => {
    const userSessionData = getItemFromStorage('sessionInfo');
    if (userSessionData) {
      await doRefresh(userSessionData[0], updateCurrentUser);
    }
  };

  const checkAndRedirectToHomePage = () => {
    if (currentUser && currentUser.isLoggedIn) {
      if (hasRoles([ROLE.SUPER_ADMIN, ROLE.PROJECT_ADMIN], currentUser)) {
        return `/${NAVIGATION_ROUTES.ORG_UNITS}`;
      }
      return `/${NAVIGATION_ROUTES.DASHBOARD}`;
    }
    return `/${NAVIGATION_ROUTES.LOGIN}`;
  };

  useEffect(() => {
    setSessionData();
  }, []);

  const Dashboard = React.lazy(() =>
    import('../pages/dashboard/DashboardPage')
  );

  const AggregateDataEntryPage = React.lazy(() =>
    import('../pages/aggregate-data-entry/AggregateDataEntryPage')
  );
  const NotificationsPage = React.lazy(() =>
    import('../pages/notifications/NotificationsPage')
  );
  const DownloadMetadataPage = React.lazy(() =>
    import('../pages/download-metadata/DownloadMetadata')
  );
  const LoginPage = React.lazy(() => import('../pages/login/LoginPage'));
  const OrgUnitsPage = React.lazy(() =>
    import('../pages/org-units/OrgUnitsPage')
  );
  const ProductKeyPage = React.lazy(() =>
    import('../pages/product-key/ProductKeyPage')
  );
  const LineListSummaryPage = React.lazy(() =>
    import('../pages/line-list-summary/LineListSummaryPage')
  );
  const LineListDataEntryPage = React.lazy(() =>
    import('../pages/line-list-data-entry/LineListDataEntryPage')
  );
  const ReportsPage = React.lazy(() => import('../pages/reports/ReportsPage'));
  const OpUnitReportPage = React.lazy(() =>
    import('../pages/reports/OpUnitReportPage')
  );
  const ProjectReportPage = React.lazy(() =>
    import('../pages/project/ProjectReportPage')
  );
  const ModuleSummaryAndApprovalPage = React.lazy(() =>
    import('../pages/module-summary/ModuleSummaryAndApprovalPage')
  );
  const LineListMultiStageDataEntryPage = React.lazy(() =>
    import('../pages/line-list-data-entry/LineListMultiStagedataEntryPage')
  );
  const LineListMultiStageSummaryPage = React.lazy(() =>
    import('../pages/line-list-summary/LineListMultiStageSummaryPage')
  );
  const SelectProjectPreference = React.lazy(() =>
    import('../pages/select-project-preference/SelectProjectPreference')
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path="*"
          element={<Navigate to={checkAndRedirectToHomePage()} replace />}
        />
        <Route
          path={NAVIGATION_ROUTES.DASHBOARD}
          element={
            <ResolveRoute>
              <PrivateRoute
                roles={[
                  ROLE.DATA_ENTRY_USER,
                  ROLE.OBSERVER,
                  ROLE.PROJECT_LEVEL_APPROVER,
                  ROLE.COORDINATION_LEVEL_APPROVER,
                ]}
              >
                <Dashboard />
              </PrivateRoute>
            </ResolveRoute>
          }
        />
        <Route path={NAVIGATION_ROUTES.AGGREGATE_DATA_ENTRY}>
          <Route
            path=":moduleId"
            element={
              <ResolveRoute>
                <PrivateRoute roles={[ROLE.DATA_ENTRY_USER]}>
                  <AggregateDataEntryPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
          <Route
            path=":moduleId/:weekSelected"
            element={
              <ResolveRoute>
                <PrivateRoute roles={[ROLE.DATA_ENTRY_USER]}>
                  <AggregateDataEntryPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
          <Route
            path=""
            element={
              <ResolveRoute>
                <PrivateRoute roles={[ROLE.DATA_ENTRY_USER]}>
                  <AggregateDataEntryPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
        </Route>

        <Route
          path={NAVIGATION_ROUTES.LINE_LIST_SUMMARY}
          element={
            <ResolveRoute>
              <PrivateRoute
                roles={[
                  ROLE.DATA_ENTRY_USER,
                  ROLE.OBSERVER,
                  ROLE.PROJECT_LEVEL_APPROVER,
                  ROLE.COORDINATION_LEVEL_APPROVER,
                ]}
              >
                <LineListSummaryPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        >
          <Route
            path=":filterBy"
            element={
              <ResolveRoute>
                <PrivateRoute
                  roles={[
                    ROLE.DATA_ENTRY_USER,
                    ROLE.OBSERVER,
                    ROLE.PROJECT_LEVEL_APPROVER,
                    ROLE.COORDINATION_LEVEL_APPROVER,
                  ]}
                >
                  <LineListSummaryPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
        </Route>

        <Route
          path={NAVIGATION_ROUTES.LINE_LIST_DATA_ENTRY}
          element={
            <ResolveRoute>
              <PrivateRoute roles={[ROLE.DATA_ENTRY_USER]}>
                <LineListDataEntryPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        >
          <Route
            path=":filterBy"
            element={
              <ResolveRoute>
                <PrivateRoute roles={[ROLE.DATA_ENTRY_USER]}>
                  <LineListDataEntryPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
        </Route>

        <Route
          path={NAVIGATION_ROUTES.OP_UNIT_REPORT}
          element={
            <ResolveRoute>
              <PrivateRoute
                roles={[
                  ROLE.DATA_ENTRY_USER,
                  ROLE.OBSERVER,
                  ROLE.PROJECT_LEVEL_APPROVER,
                  ROLE.COORDINATION_LEVEL_APPROVER,
                ]}
              >
                <OpUnitReportPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        />
        <Route
          path={NAVIGATION_ROUTES.PROJECT_REPORT}
          element={
            <ResolveRoute>
              <PrivateRoute
                roles={[
                  ROLE.DATA_ENTRY_USER,
                  ROLE.OBSERVER,
                  ROLE.PROJECT_LEVEL_APPROVER,
                  ROLE.COORDINATION_LEVEL_APPROVER,
                ]}
              >
                <ProjectReportPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        />
        <Route
          path={NAVIGATION_ROUTES.LINE_LIST_MULTI_STAGE_SUMMARY}
          element={
            <ResolveRoute>
              <PrivateRoute
                roles={[
                  ROLE.DATA_ENTRY_USER,
                  ROLE.OBSERVER,
                  ROLE.PROJECT_LEVEL_APPROVER,
                  ROLE.COORDINATION_LEVEL_APPROVER,
                ]}
              >
                <LineListMultiStageSummaryPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        >
          <Route
            path=":filterBy"
            element={
              <ResolveRoute>
                <PrivateRoute
                  roles={[
                    ROLE.DATA_ENTRY_USER,
                    ROLE.OBSERVER,
                    ROLE.PROJECT_LEVEL_APPROVER,
                    ROLE.COORDINATION_LEVEL_APPROVER,
                  ]}
                >
                  <LineListMultiStageSummaryPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
        </Route>

        <Route
          path={NAVIGATION_ROUTES.LINE_LIST_MULTI_STAGE_DATA_ENTRY}
          element={
            <ResolveRoute>
              <PrivateRoute roles={[ROLE.DATA_ENTRY_USER]}>
                <LineListMultiStageDataEntryPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        >
          <Route
            path=":filterBy"
            element={
              <ResolveRoute>
                <PrivateRoute roles={[ROLE.DATA_ENTRY_USER]}>
                  <LineListMultiStageDataEntryPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
        </Route>

        <Route
          path={NAVIGATION_ROUTES.ORG_UNITS}
          element={
            <ResolveRoute>
              <PrivateRoute roles={[ROLE.SUPER_ADMIN, ROLE.PROJECT_ADMIN]}>
                <OrgUnitsPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        />
        <Route
          path={NAVIGATION_ROUTES.NOTIFICATIONS}
          element={
            <PrivateRoute
              roles={[
                ROLE.DATA_ENTRY_USER,
                ROLE.OBSERVER,
                ROLE.PROJECT_LEVEL_APPROVER,
                ROLE.COORDINATION_LEVEL_APPROVER,
              ]}
            >
              <NotificationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={NAVIGATION_ROUTES.DOWNLOAD_METADATA}
          element={<DownloadMetadataPage />}
        />
        <Route
          path={NAVIGATION_ROUTES.UPDATE_PRODUCT_KEY}
          element={<ProductKeyPage />}
        />
        <Route
          path={NAVIGATION_ROUTES.SELECT_PROJECT_PREFERENCE}
          element={
            <ResolveRoute>
              <PrivateRoute roles={[ROLE.PROJECT_ADMIN]}>
                <SelectProjectPreference />
              </PrivateRoute>
            </ResolveRoute>
          }
        />
        <Route
          path={NAVIGATION_ROUTES.REPORTS}
          element={
            <ResolveRoute>
              <PrivateRoute
                roles={[
                  ROLE.DATA_ENTRY_USER,
                  ROLE.OBSERVER,
                  ROLE.PROJECT_LEVEL_APPROVER,
                  ROLE.COORDINATION_LEVEL_APPROVER,
                ]}
              >
                <ReportsPage />
              </PrivateRoute>
            </ResolveRoute>
          }
        />

        <Route
          path={NAVIGATION_ROUTES.LOGIN}
          element={
            <ResolveRoute>
              <LoginPage />
            </ResolveRoute>
          }
        />
        <Route
          path="/"
          element={
            <ResolveRoute>
              <Navigate to={checkAndRedirectToHomePage()} replace />
            </ResolveRoute>
          }
        />
        <Route path={NAVIGATION_ROUTES.DATA_APPROVAL}>
          <Route
            path=":moduleId"
            element={
              <ResolveRoute>
                <PrivateRoute
                  roles={[
                    ROLE.OBSERVER,
                    ROLE.PROJECT_LEVEL_APPROVER,
                    ROLE.COORDINATION_LEVEL_APPROVER,
                  ]}
                >
                  <ModuleSummaryAndApprovalPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
          <Route
            path=":moduleId/:weekSelected"
            element={
              <ResolveRoute>
                <PrivateRoute
                  roles={[
                    ROLE.OBSERVER,
                    ROLE.PROJECT_LEVEL_APPROVER,
                    ROLE.COORDINATION_LEVEL_APPROVER,
                  ]}
                >
                  <ModuleSummaryAndApprovalPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
          <Route
            path=""
            element={
              <ResolveRoute>
                <PrivateRoute
                  roles={[
                    ROLE.OBSERVER,
                    ROLE.PROJECT_LEVEL_APPROVER,
                    ROLE.COORDINATION_LEVEL_APPROVER,
                  ]}
                >
                  <ModuleSummaryAndApprovalPage />
                </PrivateRoute>
              </ResolveRoute>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
