import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '../button/Button';
import './ConfirmDialog.less';
import { getTranslatedMessage } from '../../../services/translation.service';
import useEscapeKey from '../hooks/useEscapeKey';

const ConfirmDialog = ({
  modalTitle,
  confirmationMessage,
  okLabel,
  cancelLabel,
  onOk,
  onCancel,
  showCancelBtn,
}) => {
  const nodeRef = useRef();
  const intl = useIntl();

  const handleOutsideClick = (e) => {
    if (
      nodeRef.current !== null &&
      e.target.className !== null &&
      e.target.className !== undefined &&
      e.target.className.indexOf('modal fade in show') > -1 &&
      !nodeRef.current.contains(e.target)
    ) {
      onCancel(e);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);
    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  useEscapeKey(onCancel);

  return (
    <>
      <div className="modal-backdrop fade in" />
      <div className="modal fade in show">
        <div
          ref={(node) => {
            nodeRef.current = node;
          }}
          className="modal-dialog"
        >
          <div className="modal-content">
            <div className="popupDialog">
              <div className="modal-header">
                <h4>{modalTitle}</h4>
              </div>
              <div className="modal-body">
                <p>{confirmationMessage}</p>
              </div>
              <div className="modal-footer">
                <Button
                  onClick={onOk}
                  id="ok_btn"
                  className="ok-btn btn-main"
                  label={getTranslatedMessage(intl, `${okLabel}`, 'Ok')}
                  variant="secondary"
                />
                {showCancelBtn && (
                  <Button
                    onClick={onCancel}
                    id="cancel_btn"
                    className="cancel-btn"
                    variant="default"
                    label={getTranslatedMessage(
                      intl,
                      `${cancelLabel}`,
                      'Cancel'
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
ConfirmDialog.defaultProps = {
  okLabel: 'okLabel',
  cancelLabel: 'cancelLabel',
  modalTitle: '',
  showCancelBtn: true,
  onCancel: () => {},
};

ConfirmDialog.propTypes = {
  modalTitle: PropTypes.string,
  confirmationMessage: PropTypes.string.isRequired,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  showCancelBtn: PropTypes.bool,
};

export default ConfirmDialog;
