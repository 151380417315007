import React, { useContext } from 'react';

const LocaleContext = React.createContext({
  locale: 'en',
  layoutDirection: '',
  updateLocale: () => {},
});

export default LocaleContext;

export const useLocaleContext = () => useContext(LocaleContext);
