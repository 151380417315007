import sjcl from 'sjcl';
import properties from '../conf/properties';

const createCipherText = (productKey) => {
  const cipherDetails = JSON.parse(atob(productKey));
  const { encryption } = properties;
  return JSON.stringify({
    iv: cipherDetails.iv,
    salt: cipherDetails.salt,
    ct: cipherDetails.ct,
    iter: parseInt(encryption.iter, 10),
    ks: parseInt(encryption.ks, 10),
    ts: parseInt(encryption.ts, 10),
    mode: encryption.mode,
    cipher: encryption.cipher,
  });
};

const decrypt = (text) => {
  const cipherText = createCipherText(text);
  const decryptedDetails = sjcl.decrypt(
    properties.encryption.passphrase,
    cipherText
  );
  return decryptedDetails;
};

const generateSHA256 = (message) => {
  const bitArray = sjcl.hash.sha256.hash(message);
  const digestSha256 = sjcl.codec.hex.fromBits(bitArray);
  return digestSha256;
};

export default {
  decrypt,
  generateSHA256,
};
