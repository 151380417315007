/* eslint-disable consistent-return */
import moment from 'moment';
import _ from 'lodash';
import { CHANNEL_USER_PREFERENCES_UPDATED, ROLE } from '../constants/Constants';
import { findAllByParent } from '../repositories/orgunit.repository';
import {
  getUserPreference,
  saveUserPreference,
} from '../repositories/user.preference.repository';
import { hasRoles, promisifyValue } from '../utils/helper.utils';
import { clearStorage } from '../services/storage.service';

export const saveSessionState = async (currentUser) => {
  if (currentUser && currentUser.userCredentials) {
    const userPreferences = {
      username: currentUser.userCredentials.username,
      organisationUnits: currentUser.organisationUnits,
      selectedProject: currentUser.selectedProject,
      lastUpdated: moment().toISOString(),
      userRoles: currentUser.userCredentials.userRoles,
    };
    const savedPreferences = await saveUserPreference(userPreferences);
    return savedPreferences;
  }
};

const loadUserPreferences = async (user) => {
  const userPreferences = await getUserPreference(
    user.userCredentials.username
  );
  return userPreferences;
};

export const performLogin = async (user, updateUserContext) => {
  let currentUser = {};

  const setUserPreferences = async (userPreferences) => {
    currentUser = {
      ...currentUser,
      selectedProject: userPreferences.selectedProject,
    };
    updateUserContext(currentUser);
  };

  const setDefaultPreferences = () => {
    const defaultProject = _.isEmpty(currentUser.organisationUnits)
      ? undefined
      : currentUser.organisationUnits[0];
    currentUser = {
      ...currentUser,
      selectedProject: defaultProject,
    };
    updateUserContext(currentUser);
  };

  const setUserOrgUnits = async (userPreferences) => {
    const getUserOrgUnits = async () => {
      if (hasRoles([ROLE.COORDINATION_LEVEL_APPROVER], currentUser)) {
        const allOrgUnits = await findAllByParent(user.organisationUnits[0].id);
        return allOrgUnits;
      }
      if (hasRoles([ROLE.PROJECT_ADMIN], currentUser)) {
        return userPreferences
          ? promisifyValue(userPreferences.organisationUnits)
          : promisifyValue(undefined);
      }
      return promisifyValue(user.organisationUnits);
    };

    const data = await getUserOrgUnits();
    currentUser = {
      ...currentUser,
      organisationUnits: data,
    };

    return userPreferences;
  };

  const loadSession = async (userPreferences) => {
    if (userPreferences) {
      await setUserPreferences(userPreferences);
      await promisifyValue('');
    } else {
      setDefaultPreferences();
      await promisifyValue('');
    }
    await saveSessionState(currentUser);
  };

  currentUser = {
    userCredentials: user.userCredentials,
    isLoggedIn: true,
  };

  const broadcast = () => {
    const bc = new BroadcastChannel(CHANNEL_USER_PREFERENCES_UPDATED);
    bc.postMessage('updated');
  };

  const userPreferences = await loadUserPreferences(user);
  await setUserOrgUnits(userPreferences);
  await loadSession(userPreferences);
  broadcast();
  return userPreferences;
};

export const doRefresh = async (user, updateUserContext) => {
  const userPreferences = await loadUserPreferences(user);
  const currentUser = {
    isLoggedIn: true,
    userCredentials: user.userCredentials,
    organisationUnits: userPreferences.organisationUnits,
    selectedProject: userPreferences.selectedProject,
  };
  updateUserContext(currentUser);
};

export const logout = async (currentUser) => {
  clearStorage();
  await saveSessionState(currentUser);
};
