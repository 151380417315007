/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable camelcase */
// import _ from 'lodash';

// const update_translations_store = (db, tx) => {
//   const translationStore = tx.objectStore('translations');
//   translationStore.openCursor().onsuccess = function (e) {
//     const cursor = e.target.result;
//     if (cursor) {
//       const data = cursor.value;
//       data.objectId = data.objectUid;
//       delete data.objectUid;
//       cursor.update(data);
//       cursor.continue();
//     }
//   };
// };

// const change_role_to_projectadmin = (db, tx) => {
//   const userStore = tx.objectStore('users');
//   userStore.delete('msfadmin');
//   userStore.openCursor().onsuccess = function (e) {
//     const cursor = e.target.result;
//     if (cursor) {
//       const data = cursor.value;
//       if (data.userCredentials.username === 'projectadmin') {
//         data.userCredentials.userRoles[0].name = 'Projectadmin';
//         cursor.update(data);
//       }
//       cursor.continue();
//     }
//   };
// };

// const format_event_dates = (db, tx) => {
//   const programEventStore = tx.objectStore('programEvents');
//   programEventStore.openCursor().onsuccess = function (e) {
//     const cursor = e.target.result;
//     if (cursor) {
//       const event = cursor.value;
//       event.occurredAt = dateUtils.toISODate(event.occurredAt);
//       cursor.update(event);
//       cursor.continue();
//     }
//   };
// };

// const migrate_organisation_unit_data_set_association = (db, tx) => {
//   const dataSetsStore = tx.objectStore('dataSets');
//   const dataSets = {};
//   const orgUnits = {};

//   dataSetsStore.openCursor().onsuccess = function (e) {
//     const cursor = e.target.result;
//     if (cursor) {
//       const dataSet = cursor.value;
//       dataSets[dataSet.id] = dataSet.orgUnitIds;
//       delete dataSet.orgUnitIds;
//       delete dataSet.organisationUnits;
//       cursor.update(dataSet);
//       cursor.continue();
//     } else {
//       // On DataSet Cursor completion
//       _.each(dataSets, (orgUnitIds, dataSetId) => {
//         _.each(orgUnitIds, (orgUnitId) => {
//           orgUnits[orgUnitId] = orgUnits[orgUnitId] || [];
//           orgUnits[orgUnitId].push({
//             id: dataSetId,
//           });
//         });
//       });
//       const orgUnitStore = tx.objectStore('organisationUnits');
//       orgUnitStore.getAll().onsuccess = function (e) {
//         const orgUnitsFromDB = e.target.result;
//         _.each(orgUnitsFromDB, (orgUnitFromDB) => {
//           orgUnitFromDB.dataSets = orgUnits[orgUnitFromDB.id] || [];
//           orgUnitStore.put(orgUnitFromDB);
//         });
//       };
//     }
//   };
//   dataSetsStore.deleteIndex('by_organisationUnit');
// };

// const migrate_chart_and_pivot_table_keys_from_names_to_ids = (db, tx) => {
//   const chartDefinitionsStore = tx.objectStore('chartDefinitions');
//   const chartDataStore = tx.objectStore('chartData');

//   const pivotTableDefinitionsStore = tx.objectStore('pivotTableDefinitions');
//   const pivotTableDataStore = tx.objectStore('pivotTableData');

//   chartDefinitionsStore.getAll().onsuccess = function (e) {
//     const chartDefinitions = e.target.result;
//     const chartNamesAndIdsMap = _.reduce(
//       chartDefinitions,
//       (map, chartDefinition) => {
//         map[chartDefinition.name] = chartDefinition.id;
//         return map;
//       },
//       {}
//     );

//     chartDataStore.getAll().onsuccess = function (e) {
//       const chartData = e.target.result;
//       _.each(chartData, (chartDatum) => {
//         const chartName = chartDatum.chart;
//         if (chartNamesAndIdsMap[chartName]) {
//           chartDatum.chart = chartNamesAndIdsMap[chartName];
//           chartDataStore.put(chartDatum);
//           chartDataStore.delete([chartName, chartDatum.orgUnit]);
//         }
//       });
//     };
//   };

//   pivotTableDefinitionsStore.getAll().onsuccess = function (e) {
//     const pivotTableDefinitions = e.target.result;
//     const pivotTableNamesAndIdsMap = _.reduce(
//       pivotTableDefinitions,
//       (map, pivotTableDefinition) => {
//         map[pivotTableDefinition.name] = pivotTableDefinition.id;
//         return map;
//       },
//       {}
//     );

//     pivotTableDataStore.getAll().onsuccess = function (e) {
//       const pivotTableData = e.target.result;
//       _.each(pivotTableData, (pivotTableDatum) => {
//         const pivotTableName = pivotTableDatum.pivotTable;
//         if (pivotTableNamesAndIdsMap[pivotTableName]) {
//           pivotTableDatum.pivotTable = pivotTableNamesAndIdsMap[pivotTableName];
//           pivotTableDataStore.put(pivotTableDatum);
//           pivotTableDataStore.delete([pivotTableName, pivotTableDatum.orgUnit]);
//         }
//       });
//     };
//   };
// };

// const update_change_log_keys = (db, tx) => {
//   const changeLogKeyMapper = {
//     orgUnits: 'organisationUnits',
//     orgUnitGroups: 'organisationUnitGroups',
//     datasets: 'dataSets',
//   };
//   const changeLogStore = tx.objectStore('changeLog');
//   Object.keys(changeLogKeyMapper).forEach((key) => {
//     const request = changeLogStore.get(key);
//     request.onsuccess = function (e) {
//       const changeLog = e.target.result;
//       if (changeLog) {
//         changeLogStore.delete(key);
//         changeLogStore.put({
//           type: changeLogKeyMapper[key],
//           lastUpdatedTime: changeLog.lastUpdatedTime,
//         });
//       }
//     };
//   });
// };

// const migrate_metadata_change_log = (db, tx) => {
//   const changeLogStore = tx.objectStore('changeLog');
//   const req = changeLogStore.get('metaData');
//   req.onsuccess = function (e) {
//     const metaDataChangeLog = e.target.result;
//     if (metaDataChangeLog) {
//       metadataConf.entities
//         .filter(
//           (entity) =>
//             !_.includes(
//               ['userRoles', 'attributes', 'indicators', 'programIndicators'],
//               entity
//             )
//         )
//         .forEach((entityType) => {
//           changeLogStore.put({
//             type: entityType,
//             lastUpdatedTime: metaDataChangeLog.lastUpdatedTime,
//           });
//         });
//     }
//   };
// };

// const update_orgunit_changelog = (db, tx) => {
//   const changeLogStore = tx.objectStore('changeLog');
//   const changeLogReq = changeLogStore.get('organisationUnits');
//   changeLogReq.onsuccess = function (e) {
//     const orgUnitChangeLog = e.target.result;
//     const decryptAndUpdateChangeLog = function (e) {
//       const productKey = e.target.result;
//       if (productKey) {
//         const decryptedProductKey = productKeyUtils.decrypt(
//           productKey.value
//         ) || { data: {} };
//         const allowedOrgUnitIds = _.map(
//           decryptedProductKey.data.allowedOrgUnits,
//           'id'
//         );
//         allowedOrgUnitIds.map((orgUnitId) => {
//           changeLogStore.put({
//             type: `organisationUnits:${orgUnitId}`,
//             lastUpdatedTime: orgUnitChangeLog.lastUpdatedTime,
//           });
//         });
//       }
//     };

//     if (orgUnitChangeLog) {
//       const systemSettingStore = tx.objectStore('systemSettings');
//       const req = systemSettingStore.get('productKey');
//       req.onsuccess = decryptAndUpdateChangeLog;
//     }
//   };
// };

// const clear_line_list_module_data_from_change_log = (db, tx) => {
//   const changeLogStore = tx.objectStore('changeLog');
//   const organisationUnitsStore = tx.objectStore('organisationUnits');
//   changeLogStore.getAll().onsuccess = function (e) {
//     const changeLogEntries = e.target.result;
//     _.forEach(changeLogEntries, (changeLogEntry) => {
//       if (
//         changeLogEntry.type.contains('dataValues') ||
//         changeLogEntry.type.contains('yearlyDataValues')
//       ) {
//         const slicedChangeLogKey = changeLogEntry.type.split(':');
//         const moduleId = slicedChangeLogKey[slicedChangeLogKey.length - 1];
//         organisationUnitsStore.get(moduleId).onsuccess = function (e) {
//           const module = e.target.result;
//           if (
//             customAttributes.getBooleanAttributeValue(
//               module.attributeValues,
//               customAttributes.LINE_LIST_ATTRIBUTE_CODE
//             )
//           ) {
//             changeLogStore.delete(changeLogEntry.type);
//           }
//         };
//       }
//     });
//   };
// };

const add_super_admin_user = {
  users: {
    userCredentials: {
      username: 'superadmin',
      userRoles: [
        {
          name: 'Superadmin',
        },
      ],
      disabled: false,
    },
  },
};

const add_projectadmin_user = {
  users: {
    userCredentials: {
      username: 'projectadmin',
      userRoles: [
        {
          name: 'Projectadmin',
        },
      ],
      disabled: false,
    },
  },
};

const updateScripts = [add_super_admin_user, add_projectadmin_user];

export default updateScripts;

// update_translations_store,
// change_role_to_projectadmin,
// format_event_dates,
// migrate_organisation_unit_data_set_association,
// migrate_chart_and_pivot_table_keys_from_names_to_ids,
// // update_change_log_keys,
// migrate_metadata_change_log,
// update_orgunit_changelog,
// clear_line_list_module_data_from_change_log,
// // force_charts_data_and_reports_data_to_redownload,
// ];
