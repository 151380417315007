import _ from 'lodash';
import { getPraxisdDbInstance } from '../indexedDb';
import { getAllModulesInOrgUnits } from './orgunit.repository';

const USER_PREFERENCES_STORE_NAME = 'userPreferences';

export const getUserPreference = async (username) => {
  const indexedDb = await getPraxisdDbInstance();
  const userPreference = await indexedDb
    .table(USER_PREFERENCES_STORE_NAME)
    .get(username);
  return userPreference;
};

const getAll = async () => {
  const indexedDb = await getPraxisdDbInstance();
  const userPreferences = await indexedDb
    .table(USER_PREFERENCES_STORE_NAME)
    .toArray();
  return userPreferences;
};

export const getCurrentUsersPreferences = async () => {
  const userPreferences = await getAll();
  const userPreferencesWithLastUpdated = _.filter(
    userPreferences,
    'lastUpdated'
  );
  const mostRecentlyUpdatedUserPreference = _.last(
    _.sortBy(userPreferencesWithLastUpdated, 'lastUpdated')
  );
  return mostRecentlyUpdatedUserPreference || null;
};

export const getCurrentUsersProjectIds = async () => {
  const userPreferences = await getCurrentUsersPreferences();
  return userPreferences ? _.map(userPreferences.organisationUnits, 'id') : [];
};

export const getCurrentUsersModules = async () => {
  const currentProjectIds = await getCurrentUsersProjectIds();
  const allModulesInOrgUnits = await getAllModulesInOrgUnits(currentProjectIds);
  return allModulesInOrgUnits;
};

export const getCurrentUsersUsername = async () => {
  const userPreferences = await getCurrentUsersPreferences();
  return userPreferences ? userPreferences.username : null;
};

export const saveUserPreference = async (userPreferences) => {
  const indexedDb = await getPraxisdDbInstance();
  await indexedDb.table(USER_PREFERENCES_STORE_NAME).put(userPreferences);
  return userPreferences;
};
