/* eslint-disable no-undef */
export const prodURL = PRAXIS_PROD_URL;
const url = API_URL;
const properties = {
  metadata: {
    sync: {
      intervalInMinutes: META_DATA_SYNC_INTERVAL,
    },
  },
  http: {
    timeout: 180000,
  },
  projectDataSync: {
    intervalInMinutes: 720,
    numWeeksToSync: 12,
    numWeeksToSyncOnFirstLogIn: 12,
    numWeeksForHistoricalData: 52,
    numWeeksForIncompleteHistoricalCases: 26,
    numYearsToSyncYearlyReports: 3,
  },
  dhisPing: {
    url: `${url}/favicon.ico`,
    timeoutInSeconds: 3,
    retryIntervalInMinutes: 1,
  },
  dhis: {
    url,
    authHeader: AUTH,
    productKeyLevel: 'global',
  },
  praxis: {
    version: '24.0',
    fileExtension: 'prx',
    dbName: 'praxis',
    dbForLogs: 'praxisLogs',
    platform: 'pwa',
  },
  queue: {
    maxretries: 3,
    delay: 100,
    skipRetryMessages: [],
    maxNumberOfTimesItemCanBeRescued: 5,
    minTimeInSecToIncrementItemRescuedCount: 120,
    httpGetRetryDelay: 10000,
    retryDelayConfig: {
      0: 10000,
      1: 300000,
      2: 1800000,
    },
    defaultRetryDelay: 1800000,
  },
  eventsSync: {
    maximumNumberOfEventsToSync: 10000,
    pageSize: {
      eventIds: 100,
      eventsForMultiStageHistoricalCases: 50,
      eventData: 50,
    },
    numberOfDaysToAllowResubmit: 3,
  },
  casesSync: {
    maximumNumberOfCasesToSync: 20000, // TODO: Reset limit to 10000 after getting fix from DHIS
    pageSize: {
      caseIds: 1000,
      caseData: 200,
      caseDataByTrackedEntityInstances: 5,
      enrollmentData: 40,
      trackedEntityInstanceData: 15,
    },
  },
  logging: {
    maxAgeinHours: 168,
  },
  encryption: {
    passphrase: PASSPHRASE,
    iter: ITER,
    ks: KS,
    ts: TS,
  },
  paging: {
    maxPageRequests: 500,
  },
  yearsToDisplayInProjectReport: 4,
  messageTimeout: 5000,
  devMode: process.env.NODE_ENV !== 'production',
  weeksForAutoApprove: 8,
  weeksToDisplayStatusInDashboard: 12,
  metaDataRetryLimit: 5,
  support_email: SUPPORT_EMAIL,
  organisationSettings: {
    geographicOriginDisabled: false,
    referralLocationDisabled: false,
    userNameValidations: {
      'Data entry user': 'PROJECT_CODE_PREFIX',
      'Project Level Approver': 'PROJECT_CODE_PREFIX',
      Observer: 'PROJECT_CODE_PREFIX',
      'Coordination Level Approver': 'EMAIL',
    },
    userCredentials: {
      pwa: {
        superadmin:
          '5397782adb4687321a872ec9b34a77f22f94fe2234cec148e5bb3e6b249c0989',
        projectadmin:
          'cf5fb18849746f3da8f6abdca24cafc66cdb98cacfc2ba35c88a2cbfebbf6e07',
        project_user:
          '4cc3534ed26d57c509ff443a2768165da1de947c7368493ebbc29518584d9705',
      },
    },
  },
};

export default properties;
