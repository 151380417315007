import { useEffect, useState } from 'react';

const useOutsideClick = (ref) => {
  const [outsideClickEvent, setOutsideClickEvent] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOutsideClickEvent(true);
      } else {
        setOutsideClickEvent(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
  return { outsideClickEvent };
};

export default useOutsideClick;
