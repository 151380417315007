/* eslint-disable import/no-mutable-exports */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import _ from 'lodash';

let allObjectStores = [];

const createObjectStoreObject = (stores) => {
  const list = Object.keys(stores).map((key) => ({ [key]: stores[key] }));
  allObjectStores = [...allObjectStores, ...list];
};

const getObjectStoreFormat = (stores) => {
  const storeObj = {};
  _.each(stores, (type) => {
    storeObj[type] = 'id';
  });
  return storeObj;
};

const getObjectStoreWithKeyFormat = (storeName, key) => ({ [storeName]: key });

const getObjectStoreWithKeyAndIndexFormat = (storeName, key, indexes) => {
  const store = allObjectStores.find(
    (storeObj) => Object.keys(storeObj)[0] === storeName
  );
  if (store) {
    const hasKey = Object.values(store)[0]
      .split(',')
      .some((indexKey) => indexKey === key);
    const indexesStr = `${Object.values(store).join(',')},${indexes.join(',')}`;
    if (hasKey) {
      return { [storeName]: `${indexesStr}` };
    }
    return { [storeName]: `${key},${indexesStr}` };
  }
  return { [storeName]: `${key},${indexes.join(',')}` };
};

const syncable_types = [
  'categories',
  'categoryCombos',
  'categoryOptionCombos',
  'categoryOptions',
  'dataElements',
  'sections',
  'programStages',
  'optionSets',
  'organisationUnitLevels',
];
createObjectStoreObject(getObjectStoreFormat(syncable_types));
createObjectStoreObject(
  getObjectStoreFormat(['indicators', 'programIndicators'])
);
createObjectStoreObject(getObjectStoreWithKeyFormat('systemSettings', 'key'));
createObjectStoreObject(
  getObjectStoreWithKeyFormat('patientOrigin', 'orgUnit')
);
createObjectStoreObject(
  getObjectStoreWithKeyFormat('excludedDataElements', 'orgUnit')
);
createObjectStoreObject(getObjectStoreWithKeyFormat('changeLog', 'type'));
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('dataValues', '[period+orgUnit]', [
    'period',
    'orgUnit',
  ])
);
createObjectStoreObject(
  getObjectStoreWithKeyFormat('users', 'userCredentials.username')
);
// createObjectStoreObject(
//   getObjectStoreWithKeyFormat('localUserCredentials', 'username')
// );
createObjectStoreObject(
  getObjectStoreWithKeyFormat('userPreferences', 'username')
);
// createObjectStoreObject(
//   getObjectStoreWithKeyAndIndexFormat('translations', '[objectUid+locale]', [
//     'locale',
//   ])
// );
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('approvals', '[period+orgUnit]', [
    'period',
  ])
);
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('programs', 'id', ['*orgUnitIds'])
);
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('organisationUnits', 'id', [
    '*parentId',
    'level',
  ])
);
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('programEvents', 'event', [
    '[program+orgUnit+period]',
    '[program+orgUnit+localStatus]',
    'eventCode',
    'eventDate',
    'orgUnit',
    'period',
  ])
);
createObjectStoreObject(getObjectStoreWithKeyFormat('orgUnitGroups', 'id'));
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('dataSets', 'id', ['*orgUnitIds'])
);
createObjectStoreObject(
  getObjectStoreWithKeyFormat('organisationUnitGroupSets', 'id')
);
createObjectStoreObject(getObjectStoreWithKeyFormat('charts', 'name'));
createObjectStoreObject(
  getObjectStoreWithKeyFormat('chartData', '[chart+orgUnit]')
);
createObjectStoreObject(getObjectStoreWithKeyFormat('dataElementGroups', 'id'));

createObjectStoreObject(
  getObjectStoreWithKeyFormat('referralLocations', 'orgUnit')
);

createObjectStoreObject(
  getObjectStoreWithKeyFormat('referredFromLocations', 'orgUnit')
);

createObjectStoreObject(getObjectStoreWithKeyFormat('pivotTables', 'name'));

createObjectStoreObject(
  getObjectStoreWithKeyFormat('pivotTableData', '[pivotTable+orgUnit]')
);

createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('translations', 'id', ['locale'])
);

createObjectStoreObject(
  getObjectStoreWithKeyFormat('dataSyncFailure', '[moduleId+period]')
);
createObjectStoreObject(getObjectStoreWithKeyFormat('chartDefinitions', 'id'));
createObjectStoreObject(
  getObjectStoreWithKeyFormat('pivotTableDefinitions', 'id')
);
createObjectStoreObject(
  getObjectStoreWithKeyFormat('excludedLineListOptions', 'moduleId')
);
createObjectStoreObject(getObjectStoreWithKeyFormat('attributes', 'id'));
createObjectStoreObject(getObjectStoreWithKeyFormat('userRoles', 'id'));
createObjectStoreObject(getObjectStoreWithKeyFormat('options', 'id'));
createObjectStoreObject(getObjectStoreWithKeyFormat('optionGroups', 'id'));
createObjectStoreObject(
  getObjectStoreWithKeyFormat('programStageSections', 'id')
);
createObjectStoreObject(
  getObjectStoreWithKeyFormat('eventReportDefinitions', 'id')
);
createObjectStoreObject(
  getObjectStoreWithKeyFormat('eventReportData', '[eventReport+orgUnit]')
);
createObjectStoreObject(getObjectStoreWithKeyFormat('legendSets', 'id'));
// createObjectStoreObject(getObjectStoreWithKeyFormat('legends', 'id'));
createObjectStoreObject(
  getObjectStoreWithKeyFormat('trackedEntityAttributes', 'id')
);
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('enrollments', 'enrollment', [
    '[program+orgUnit+status]',
    'trackedEntityInstance',
  ])
);
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat(
    'trackedEntityInstances',
    'trackedEntityInstance',
    ['[orgUnit+caseCode]']
  )
);
createObjectStoreObject(
  getObjectStoreWithKeyAndIndexFormat('programEvents', 'event', ['enrollment'])
);
// createObjectStoreObject(getObjectStoreWithKeyFormat('programStages', 'id'));

export default allObjectStores;
