/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import platformConfig from '../pwa/pwa.properties';
import { subtractWeeks, toDhisFormat } from './date.utils';
import mergedProperties from '../conf/properties';
import hustleUtil, { getHustleInstance } from './hustle.util';
import { getPraxisdDbInstance } from '../indexedDb';

const PRAXIS_DB = platformConfig.praxis.dbName;
const HUSTLE_DB = platformConfig.praxis.dbForHustle;
const CHUNK_SIZE = 5000;
const backupByPeriodStores = ['dataValues', 'programEvents'];
const storesToSkipSpecificData = {
  systemSettings: {
    keyPathValues: ['productKey', 'praxisUid'],
    keyPath: 'key',
  },
};

const backupByPeriod = async (tableName, indexedDb) => {
  const startDate = subtractWeeks(
    mergedProperties.projectDataSync.numWeeksToSync
  );
  const startPeriod = toDhisFormat(moment(startDate));
  const endPeriod = toDhisFormat(moment());
  const data = await indexedDb
    .table(tableName)
    .where('period')
    .between(startPeriod, endPeriod, true, true)
    .toArray();
  return data;
};

const backupAll = async (tableName, indexedDb, dbName) => {
  if (dbName === HUSTLE_DB) {
    return hustleUtil.backupAll(tableName);
  }
  return indexedDb.table(tableName).toArray();
};

const skipSpecificData = async (
  tableName,
  indexedDb,
  keyPathValues,
  keyPath
) => {
  const data = await indexedDb.table(tableName).toArray();
  return _.filter(
    data,
    (eachEntry) => !_.includes(keyPathValues, eachEntry[keyPath])
  );
};

const backUpTables = async (indexedDb, dbName, tableNames) => {
  const data = [];
  for (const name of tableNames) {
    let callback;
    if (!_.includes(_.keys(storesToSkipSpecificData), name)) {
      callback =
        _.includes(backupByPeriodStores, name) && dbName === PRAXIS_DB
          ? backupByPeriod
          : backupAll;
    } else {
      const storeToSkip = storesToSkipSpecificData[name];
      callback = _.partial(
        skipSpecificData,
        _,
        _,
        storeToSkip.keyPathValues,
        storeToSkip.keyPath
      );
    }
    data.push(await callback(name, indexedDb, dbName));
  }
  return _.zipObject(tableNames, data);
};

const backupDB = async (indexedDb, dbName) => {
  if (dbName === HUSTLE_DB) {
    const hustleTables = await hustleUtil.backup(dbName);
    return backUpTables(indexedDb, dbName, hustleTables);
  }
  const tables = await indexedDb.tables;
  const tableNames = _.map(tables, (table) => table.name);

  return backUpTables(indexedDb, dbName, tableNames);
};

const getDbInstance = async (dbName) => {
  const dbLookUp = {
    [HUSTLE_DB]: getHustleInstance,
    [PRAXIS_DB]: getPraxisdDbInstance,
  };
  return dbLookUp[dbName]();
};

const encodeBase64 = (data) =>
  btoa(unescape(encodeURIComponent(JSON.stringify(data))));

async function backupEntireDB() {
  let indexedDb = await getDbInstance(PRAXIS_DB);
  const backupPraxis = await backupDB(indexedDb, PRAXIS_DB);
  const praxisData = _.reduce(
    backupPraxis,
    (result, value, key) => {
      const valueChunks = _.chunk(value, CHUNK_SIZE);
      _.each(valueChunks, (chunk, index) => {
        result[`${PRAXIS_DB}__${key}__${index}`] = encodeBase64(chunk);
      });
      return result;
    },
    {}
  );
  indexedDb = await getDbInstance(HUSTLE_DB);
  const hustleData = await backupDB(indexedDb, HUSTLE_DB);
  indexedDb = await getDbInstance(PRAXIS_DB);
  return _.merge(praxisData, {
    hustle: encodeBase64(hustleData),
  });
}

export default backupEntireDB;
