/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable func-names */
/* eslint-disable import/no-cycle */
import { APP_EVENTS } from '../constants/Constants';
import '@tw-msf/hustle';

const jobComparator = function (itemToBeCompared, itemComparedWith) {
  const typeEquals = _.isEqual(itemComparedWith.type, itemToBeCompared.type);
  const dataEquals = _.isEqual(itemComparedWith.data, itemToBeCompared.data);
  return typeEquals && dataEquals;
};

const HustleWrapper = (function () {
  let instance;
  async function createInstance() {
    const hustleObject = new Hustle({
      tubes: ['dataValues'],
    });

    hustleObject.promisify();
    await hustleObject.open({
      success: checkHustleQueueCount,
      error: checkHustleQueueCount,
    });
    return hustleObject;
  }

  return {
    async getInstance() {
      if (!instance) {
        instance = await createInstance();
      }
      return instance;
    },
  };
})();

export const getHustleInstance = async () => {
  const hustleInstance = await HustleWrapper.getInstance();
  return hustleInstance;
};

const publishToHustle = async (request, objectStore, isPublishOnce = false) => {
  let options = {
    tube: objectStore,
  };
  if (isPublishOnce) {
    options = { ...options, comparator: jobComparator };
  }
  const hustleObject = await getHustleInstance();
  const addJobsToHustle = await hustleObject.Queue.put(request, options);
  await checkHustleQueueCount(addJobsToHustle);

  return addJobsToHustle;
};
const getCountFromHustle = async () => {
  const hustleObject = await getHustleInstance();
  const jobCount = await hustleObject.Queue.count_ready('dataValues');
  return jobCount;
};

const getReservedCountFromHustle = async () => {
  const hustleObject = await getHustleInstance();
  const reservedJobCount = await hustleObject.Queue.count_reserved();
  return reservedJobCount;
};

const rescueReservedItems = async (
  maxNumberOfTimesItemCanBeRescued,
  minTimeToIncrementItemRescuedCount
) => {
  const hustleObject = await getHustleInstance();
  const options = {
    maxRescueLimit: maxNumberOfTimesItemCanBeRescued,
    rescueTimeLimitInSeconds: minTimeToIncrementItemRescuedCount,
  };
  return hustleObject.Queue.rescue_reserved_items(options);
};

const checkHustleQueueCount = async () => {
  const count = await getCountFromHustle();
  const reservedCount = await getReservedCountFromHustle();
  const data = { count, reservedCount };
  const bc = new BroadcastChannel(APP_EVENTS.QUEUE_SYNC_CHANGE);
  bc.postMessage(data);
};

const wipe = async (name) => {
  const hustle = await getHustleInstance();
  await hustle.clearHustle(name);
  await checkHustleQueueCount();
};

const addItemToHustle = async (data, name) => {
  const hustle = await getHustleInstance();
  await hustle.insertDataInHustle(data, name);
  await checkHustleQueueCount();
};

const backup = async (dbName) => {
  const hustle = await getHustleInstance();
  return hustle.backupDB(dbName);
};
const backupAll = async (dbName) => {
  const hustle = await getHustleInstance();
  return hustle.backupAll(dbName);
};

export default {
  getHustleInstance,
  publishToHustle,
  getCountFromHustle,
  getReservedCountFromHustle,
  rescueReservedItems,
  checkHustleQueueCount,
  wipe,
  addItemToHustle,
  backup,
  backupAll,
};
