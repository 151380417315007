import React, { useContext } from 'react';

const LoadingContext = React.createContext({
  loading: false,
  message: '',
  showLoading: () => {},
  hideLoading: () => {},
  setLoadingMessage: () => {},
});

export default LoadingContext;

export const useLoadingContext = () => useContext(LoadingContext);
