/* eslint-disable */
import moment from 'moment';
import * as _ from 'lodash';
import { getPraxisdDbInstance } from '../indexedDb';
import customAttributes from '../models/custom.attributes';
import { promisifyValue } from '../utils/helper.utils';

const ORGANISATION_UNITS_STORE_NAME = 'organisationUnits';

const addParentIdField = (payload) =>
  _.map(payload, (p) => {
    p.parentId = p.parent ? p.parent.id : undefined;
    return p;
  });

const addClientLastUpdatedField = (payload) =>
  _.map(payload, (p) => {
    p.clientLastUpdated = moment().toISOString();
    return p;
  });

export const upsertOrganisationUnit = async (payload) => {
  payload = _.isArray(payload) ? payload : [payload];
  payload = addClientLastUpdatedField(payload);
  payload = addParentIdField(payload);
  const indexedDb = await getPraxisdDbInstance();
  await indexedDb.table(ORGANISATION_UNITS_STORE_NAME).bulkPut(payload);
  return payload;
};

export const getChildOrgUnitNames = async (parentIds) => {
  parentIds = _.isArray(parentIds) ? parentIds : [parentIds];
  const indexedDb = await getPraxisdDbInstance();
  const organizationUnits = await indexedDb
    .table(ORGANISATION_UNITS_STORE_NAME)
    .where('parentId')
    .anyOf(parentIds)
    .toArray();
  return organizationUnits.map((orgUnit) => orgUnit.name.toLowerCase());
};

export const getAllOrgUnits = async () => {
  const indexedDb = await getPraxisdDbInstance();
  const organizationUnits = await indexedDb
    .table(ORGANISATION_UNITS_STORE_NAME)
    .toArray();
  return organizationUnits;
};

const isOfType = (orgUnit, type) =>
  customAttributes.getAttributeValue(
    orgUnit.attributeValues,
    customAttributes.TYPE
  ) === type;

export const getAllProjects = () => {
  const filterProjects = (orgUnits) =>
    _.filter(orgUnits, (orgUnit) => isOfType(orgUnit, 'Project'));

  return getAllOrgUnits().then(filterProjects);
};

const rejectCurrentAndDisabled = (orgUnits) =>
  _.filter(
    orgUnits,
    (ou) =>
      customAttributes.getBooleanAttributeValue(
        ou.attributeValues,
        customAttributes.NEW_DATA_MODEL_CODE
      ) &&
      !customAttributes.getBooleanAttributeValue(
        ou.attributeValues,
        customAttributes.DISABLED_CODE
      )
  );

export const findAllByParent = async (parentIds, rejectDisabled) => {
  rejectDisabled = _.isUndefined(rejectDisabled) ? true : rejectDisabled;
  parentIds = _.isArray(parentIds) ? parentIds : [parentIds];
  const indexedDb = await getPraxisdDbInstance();
  const organizationUnits = await indexedDb
    .table(ORGANISATION_UNITS_STORE_NAME)
    .where('parentId')
    .anyOf(parentIds)
    .toArray();
  if (rejectDisabled) return rejectCurrentAndDisabled(organizationUnits);
  return organizationUnits;
};

export const getOrgUnit = async (orgUnitId) => {
  const indexedDb = await getPraxisdDbInstance();
  const organizationUnits = await indexedDb
    .table(ORGANISATION_UNITS_STORE_NAME)
    .where('id')
    .equals(orgUnitId)
    .toArray();
  return organizationUnits[0];
};

export const getParentProject = async (orgUnitId) => {
  const orgUnit = await getOrgUnit(orgUnitId);
  if (isOfType(orgUnit, 'Project')) {
    return orgUnit;
  } else {
    return await getParentProject(orgUnit.parent.id);
  }
};

const getChildrenOfTypeInOrgUnits = async (orgUnitIds, requestedType) => {
  const partitionRequestedOrgUnits = function (orgunits) {
    return _.partition(orgunits, function (orgUnit) {
      return isOfType(orgUnit, requestedType);
    });
  };

  const partitionAndGetChildOrgUnits = function (orgUnits) {
    const partitionedOrgUnits = partitionRequestedOrgUnits(orgUnits);
    const requestedOrgUnits = partitionedOrgUnits[0];
    const otherOrgUnits = partitionedOrgUnits[1];
    if (_.isEmpty(otherOrgUnits)) {
      return requestedOrgUnits;
    }

    return getChildOrgUnits(otherOrgUnits).then(function (childOrgUnits) {
      return requestedOrgUnits.concat(childOrgUnits);
    });
  };

  const getChildOrgUnits = function (orgUnits) {
    const ids = _.map(orgUnits, 'id');
    return findAllByParent(ids).then(partitionAndGetChildOrgUnits);
  };

  const ids = _.flatten([orgUnitIds]);
  return findAll(ids)
    .then(partitionAndGetChildOrgUnits)
    .then(function (data) {
      return _.uniq(data);
    });
};

export const findAll = async (orgUnitIds) => {
  const indexedDb = await getPraxisdDbInstance();
  const organizationUnits = await indexedDb
    .table(ORGANISATION_UNITS_STORE_NAME)
    .where('id')
    .anyOf(orgUnitIds)
    .toArray();
  return organizationUnits;
};

export const getAllOpUnitsInOrgUnits = async (orgUnitIds) => {
  return await getChildrenOfTypeInOrgUnits(orgUnitIds, 'Operation Unit');
};

export const getAllModulesInOrgUnits = async (orgUnitIds) => {
  return await getChildrenOfTypeInOrgUnits(orgUnitIds, 'Module');
};

const getAll = async () => {
  const indexedDb = await getPraxisdDbInstance();
  const organizationUnits = await indexedDb
    .table(ORGANISATION_UNITS_STORE_NAME)
    .toArray();
  return rejectCurrentAndDisabled(organizationUnits);
};

export const getOrgUnitAndDescendants = async (maxLevel, orgUnitId) => {
  const getAllWithinMaxLevel = async () => {
    const allOrgUnits = await getAll();
    return _.filter(allOrgUnits, (orgUnit) => {
      return orgUnit.level <= maxLevel;
    });
  };

  const groupByParentId = (orgUnits) => {
    return _.groupBy(orgUnits, 'parent.id');
  };

  const getRootOrgUnit = (orgUnits, orgUnitsGroupedByParentId) => {
    return orgUnitId
      ? _.find(orgUnits, { id: orgUnitId })
      : _.head(orgUnitsGroupedByParentId[undefined]);
  };

  const getDescendantOrgUnits = (orgUnits, orgUnitsGroupedByParentId) => {
    return _.compact(
      _.flatten(
        _.map(orgUnits, (orgUnit) => {
          return orgUnitsGroupedByParentId[orgUnit.id];
        })
      )
    );
  };

  const getAllOrgUnits = (allOrgUnits) => {
    let orgUnitAndDescendants = [];
    const orgUnitsGroupedByParentId = groupByParentId(allOrgUnits);

    const rootOrgUnit = getRootOrgUnit(allOrgUnits, orgUnitsGroupedByParentId);
    orgUnitAndDescendants = orgUnitAndDescendants.concat(rootOrgUnit);
    let descendantOrgUnits = orgUnitsGroupedByParentId[rootOrgUnit.id];

    while (descendantOrgUnits && descendantOrgUnits.length > 0) {
      orgUnitAndDescendants = orgUnitAndDescendants.concat(descendantOrgUnits);
      descendantOrgUnits = getDescendantOrgUnits(
        descendantOrgUnits,
        orgUnitsGroupedByParentId
      );
    }

    return orgUnitAndDescendants;
  };

  const allOrgUnits = await getAllWithinMaxLevel();
  return getAllOrgUnits(allOrgUnits);
};

export const getAssociatedOrganisationUnitGroups = (moduleOrOriginId) => {
  const getAssociations = (orgUnits) => {
    return getOrgUnit(moduleOrOriginId).then((enrichedModuleOrOrigin) => {
      const isOrigin = _.some(enrichedModuleOrOrigin.attributeValues, {
        value: 'Patient Origin',
      });
      const module =
        isOrigin === true
          ? _.find(orgUnits, {
              id: enrichedModuleOrOrigin.parent.id,
            })
          : enrichedModuleOrOrigin;
      const opUnit = _.find(orgUnits, {
        id: module.parent.id,
      });
      const project = _.find(orgUnits, {
        id: opUnit.parent.id,
      });

      return _.map(opUnit.organisationUnitGroups, 'id').concat(
        _.map(project.organisationUnitGroups, 'id')
      );
    });
  };

  return getAll().then(getAssociations);
};

export const associateDataSetsToOrgUnits = async (dataSetIds, orgUnits) => {
  const indexedDb = await getPraxisdDbInstance();
  return Promise.all(
    _.map(orgUnits, (orgUnit) => {
      var payload = orgUnit;
      var dataSetsToAdd = _.transform(dataSetIds, (result, dataSetId) => {
        var dataSetToAdd = { id: dataSetId };
        if (!_.some(payload.dataSets, dataSetToAdd)) {
          result.push({ id: dataSetId });
        }
      });
      payload.dataSets = payload.dataSets
        ? payload.dataSets.concat(dataSetsToAdd)
        : [].concat(dataSetsToAdd);
      indexedDb.table(ORGANISATION_UNITS_STORE_NAME).put(payload);
      return payload;
    })
  );
};

export const getAllOriginsByName = async (
  opUnit,
  originName,
  rejectDisabledOrigins
) => {
  const modules = await findAllByParent(opUnit.id);
  const moduleIds = _.map(modules, 'id');
  const origins = await findAllByParent(moduleIds, rejectDisabledOrigins);
  const originsList = _.remove(origins, {
    name: originName,
  });
  return originsList;
};

export const getAllDataSetsForOrgUnit = async function (orgUnitId) {
  const orgUnit = await getOrgUnit(orgUnitId);
  return orgUnit.dataSets;
};

export const enrichWithParent = (orgUnit) => {
  const getAndStoreParent = async function (orgUnit) {
    if (!orgUnit.parent) return promisifyValue(orgUnit);

    const parent = await getOrgUnit(orgUnit.parent.id);
    orgUnit.parent = parent;
    return orgUnit;
  };
  return _.isArray(orgUnit)
    ? Promise.all(_.map(orgUnit, getAndStoreParent))
    : getAndStoreParent(orgUnit);
};

export const upsertDhisDownloadedData = async (payload) => {
  const indexedDb = await getPraxisdDbInstance();
  payload = addParentIdField(payload);

  await indexedDb.table(ORGANISATION_UNITS_STORE_NAME).bulkPut(payload);
  return payload;
};

export default {
  findAll,
  findAllByParent,
  getOrgUnit,
  getAllOpUnitsInOrgUnits,
  getAllProjects,
  getChildOrgUnitNames,
  upsertOrganisationUnit,
  getParentProject,
  getAllModulesInOrgUnits,
  getAssociatedOrganisationUnitGroups,
  getAllOriginsByName,
  associateDataSetsToOrgUnits,
  getAllDataSetsForOrgUnit,
  enrichWithParent,
  upsertDhisDownloadedData,
};
