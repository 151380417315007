import {
  filter,
  each,
  isArray,
  isUndefined,
  find,
  map,
  includes,
  flattenDeep,
  head,
  last,
  reject,
} from 'lodash';
import translationMessages from '../translations/index';

const TRANSLATABLE_OBJECT_PROPERTIES = [
  'name',
  'description',
  'formName',
  'shortName',
  'displayName',
  'title',
];
const TRANSLATABLE_PROPERTIES_MAP = {
  name: 'NAME',
  description: 'DESCRIPTION',
  formName: 'FORM_NAME',
  shortName: 'SHORT_NAME',
  title: 'SHORT_NAME',
};
const TRANSLATABLE_ENTITIES = [
  'sections',
  'dataElements',
  'headers',
  'programStages',
  'programStageSections',
  'columnConfigurations',
  'programStageDataElements',
  'dataElement',
  'optionSet',
  'options',
  'dataValues',
  'attribute',
  'organisationUnitGroups',
  'organisationUnitGroupSets',
  'programTrackedEntityAttributes',
  'trackedEntityAttribute',
  'subSection',
];

export const getMessages = async (locale) => {
  try {
    const messages = translationMessages[locale];
    return messages;
  } catch (e) {
    return undefined;
  }
};

export const getTranslatedMessage = (
  intl,
  id,
  defaultMessage,
  values = undefined
) => intl.formatMessage({ id, defaultMessage }, values);

const getTranslationFromCollection = (
  translationsCollection,
  property,
  defaultValue
) => {
  const translationObject = find(translationsCollection, { property });
  return (translationObject && translationObject.value) || defaultValue;
};

const getTranslationFromObject = (object, property, locale, defaultValue) => {
  if (object && object.translations) {
    return getTranslationFromCollection(
      filter(object.translations, { locale }),
      TRANSLATABLE_PROPERTIES_MAP[property],
      defaultValue
    );
  }
  const translations = getMessages(locale);
  return getTranslationFromCollection(
    translations[object.id],
    property,
    defaultValue
  );
};

const translateObject = (objectToBeTranslated, locale) => {
  each(TRANSLATABLE_OBJECT_PROPERTIES, (property) => {
    const translationValue = getTranslationFromObject(
      objectToBeTranslated,
      property,
      locale
    );
    if (translationValue) {
      // eslint-disable-next-line no-param-reassign
      objectToBeTranslated[property] = translationValue;
    }
  });

  each(TRANSLATABLE_ENTITIES, (entity) => {
    const nestedEntity = objectToBeTranslated[entity];
    if (nestedEntity) {
      // eslint-disable-next-line no-use-before-define
      translate(nestedEntity, locale);
    }
  });

  return objectToBeTranslated;
};

export const translate = (objectToBeTranslated, locale) => {
  if (locale === 'en' || isUndefined(objectToBeTranslated)) {
    return objectToBeTranslated;
  }

  return isArray(objectToBeTranslated)
    ? objectToBeTranslated.map((obj) => translate(obj, locale))
    : translateObject(objectToBeTranslated, locale);
};

export const getTranslationForProperty = (
  object,
  property,
  locale,
  defaultValue
) => getTranslationFromObject(object, property, locale, defaultValue);

export const translateReferralLocations = (
  arrayOfObjectsToBeTranslated,
  locale
) => {
  if (locale === 'en' && !isUndefined(arrayOfObjectsToBeTranslated)) {
    return arrayOfObjectsToBeTranslated;
  }
  return map(arrayOfObjectsToBeTranslated, (objectToBeTranslated) => {
    each(TRANSLATABLE_OBJECT_PROPERTIES, (property) => {
      const translationValue = getTranslationFromObject(
        objectToBeTranslated,
        property,
        locale
      );
      if (translationValue) {
        /* eslint-disable no-param-reassign */
        objectToBeTranslated[property] = translationValue;
      }
    });
    each(objectToBeTranslated, (value, key) => {
      if (isArray(value) && !includes('dataElements', key)) {
        each(value, (object) => {
          translateReferralLocations([object], locale);
        });
      }
    });
    return objectToBeTranslated;
  });
};

const translateMonthlyPeriods = (monthlyPeriods, intl) => {
  const SEPARATOR = ' ';
  each(monthlyPeriods, (period) => {
    const monthToBeTranslated = head(period.name.split(SEPARATOR));
    const year = last(period.name.split(SEPARATOR));
    const translatedMonth = getTranslatedMessage(
      intl,
      monthToBeTranslated,
      monthToBeTranslated
    );
    period.name = translatedMonth + SEPARATOR + year;
  });
};

export const translateChartData = (charts, intl, locale) => {
  if (locale === 'en') {
    return charts;
  }

  return map(charts, (chart) => {
    const dimensions = flattenDeep([chart.series, chart.categories]);
    const translatableDimensions = reject(dimensions, 'periodDimension');
    const periodDimensions = filter(dimensions, 'periodDimension');

    translate(translatableDimensions, locale);
    if (chart.monthlyChart) {
      translateMonthlyPeriods(periodDimensions, intl);
    }
    return chart;
  });
};

export const translateReportTableData = (
  reportTableDataObjects,
  intl,
  locale
) => {
  if (locale === 'en') {
    return reportTableDataObjects;
  }

  return map(reportTableDataObjects, (reportTableDataObject) => {
    const rowsAndColumns =
      reportTableDataObject.referredToLocationReport ||
      reportTableDataObject.referredFromLocationReport
        ? flattenDeep([
            reportTableDataObject.columns,
            reportTableDataObject.columnConfigurations,
          ])
        : flattenDeep([
            reportTableDataObject.rows,
            reportTableDataObject.columns,
            reportTableDataObject.columnConfigurations,
          ]);
    const translatableDimensions = reject(rowsAndColumns, 'periodDimension');
    const periodDimensions = filter(rowsAndColumns, 'periodDimension');

    translate(translatableDimensions, locale);
    if (
      reportTableDataObject.monthlyReport ||
      reportTableDataObject.yearlyReport
    ) {
      translateMonthlyPeriods(periodDimensions, intl);
    }

    return reportTableDataObject;
  });
};

export default {
  getMessages,
  getTranslatedMessage,
  translateReferralLocations,
  translateChartData,
  translateReportTableData,
};
