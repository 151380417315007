export const ROLE = {
  SUPER_ADMIN: 'Superadmin',
  PROJECT_ADMIN: 'Projectadmin',
  OBSERVER: 'Observer',
  DATA_ENTRY_USER: 'Data entry user',
  PROJECT_LEVEL_APPROVER: 'Project Level Approver',
  COORDINATION_LEVEL_APPROVER: 'Coordination Level Approver',
};
export const CHANNEL_USER_PREFERENCES_UPDATED = 'userPreferencesUpdated';

export const USER = {
  SUPER_ADMIN: 'superadmin',
  PROJECT_ADMIN: 'projectadmin',
};

export const NAVIGATION_ROUTES = {
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  AGGREGATE_DATA_ENTRY: 'aggregate-data-entry',
  LINE_LIST_SUMMARY: 'line-list-summary/:module',
  LINE_LIST_DATA_ENTRY: '/line-list-data-entry/:module',
  LINE_LIST_MULTI_STAGE_SUMMARY: '/line-list-multi-stage-summary/:module',
  LINE_LIST_MULTI_STAGE_DATA_ENTRY: '/line-list-multi-stage-data-entry/:module',
  ORG_UNITS: 'org-units',
  DOWNLOAD_METADATA: 'download-metadata',
  UPDATE_PRODUCT_KEY: 'update-product-key',
  SELECT_PROJECT_PREFERENCE: 'select-project-preference',
  NOTIFICATIONS: 'notifications',
  DATA_APPROVAL: 'data-approval',
  REPORTS: 'reports/:moduleId',
  OP_UNIT_REPORT: 'opUnitReport/:opUnitId',
  PROJECT_REPORT: 'projectReport',
};

export const HUSTLE_DATA_TYPE = {
  UPSERT_ORG_UNIT: 'upsertOrgUnit',
  DOWNLOAD_METADATA: 'downloadMetadata',
  DOWNLOAD_MODULE_DATA_FOR_PROJECT: 'downloadModuleDataForProject',
  DOWNLOAD_REPORT_DEFINITIONS: 'downloadReportDefinitions',
  DOWNLOAD_REPORT_DATA: 'downloadReportData',
  DOWNLOAD_HISTORICAL_DATA: 'downloadHistoricalData',
  UPLOAD_PATIENT_ORIGIN_DETAILS: 'uploadPatientOriginDetails',
  UPSERT_ORG_UNIT_GROUPS: 'upsertOrgUnitGroups',
  UPLOAD_EXCLUDED_DATA_ELEMENTS: 'uploadExcludedDataElements',
  UPLOAD_REFERRAL_LOCATIONS: 'uploadReferralLocations',
  UPLOAD_REFERRED_FROM_LOCATIONS: 'uploadReferredFromLocations',
  ASSOCIATE_ORG_UNIT_TO_DATA_SET: 'associateOrgUnitToDataset',
  ASSOCIATE_ORG_UNIT_TO_PROGRAM: 'associateOrgunitToProgram',
  SYNC_MODULE_DATA: 'syncModuleDataBlock',
  DOWNLOAD_PROJECT_DATA_FOR_ADMIN: 'downloadProjectDataForAdmin',
  CREATE_USER: 'createUser',
  UPDATE_USER: 'updateUser',
  UPLOAD_EXCLUDED_OPTIONS: 'uploadExcludedOptions',
  SYNC_ORG_UNITS: 'syncOrgUnit',
};

export const APP_EVENTS = {
  MULTIPLE_TABS: 'multipleTabs',
  DHIS_ONLINE: 'dhisOnline',
  DHIS_OFFLINE: 'dhisOffline',
  START_BG_APP: 'startBgApp',
  STOP_BG_APP: 'stopBgApp',
  QUEUE_SYNC_CHANGE: 'syncQueueChange',
  ONLINE: 'online',
  OFFLINE: 'offline',
  LOAD_APP: 'loadApp',
  PRODUCT_KEY_EXPIRED: 'productKeyExpired',
  PRODUCT_KEY_UPDATED: 'productKeyUpdated',
};

export const ERROR_CODES = {
  UNAUTHORISED: 'UNAUTHORISED',
  NOT_FOUND: 'NOT_FOUND',
  NETWORK_UNAVAILABLE: 'NETWORK_UNAVAILABLE',
};

export const ROLE_COORDINATION_LEVEL_APPROVER = 'Coordination Level Approver';

export const MENU_TABS = {
  HOME: 'Home',
  APPROVE_DATA: 'Approve Data',
  AGGREGATED_DATA: 'Aggregated Data',
  AGGREGATE_DATA_ENTRY: 'Aggregate Data Entry',
  LINE_LIST_DATA_ENTRY: 'LineList Data Entry',
  REPORTS: 'Reports',
};
