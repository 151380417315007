/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { APP_EVENTS } from './constants/Constants';
import { createDb } from './indexedDb';
import { getUserLocale } from './repositories/system.setting.repository';
import registerServiceWorker, {
  clientId,
} from './service-worker/service.worker.registration';
import './styles/style.less';
import configureLogger from './utils/indexeddb.logger.util';
import { sendMessage } from './utils/pwa.utils';

createDb();
registerServiceWorker();

const channel = new BroadcastChannel(APP_EVENTS.MULTIPLE_TABS);

channel.addEventListener('message', async (event) => {
  const bodyEl = document.getElementsByTagName('body')[0];

  const multipleTabsOpen =
    event.data.multipleTabsAreOpen && event.data.clientId === clientId;

  if (!multipleTabsOpen) {
    if (
      event.data.type === 'focusActiveTab' &&
      +self.localStorage.getItem('clientId') === clientId
    ) {
      const locale = await getUserLocale();
      const message =
        locale === 'en' || locale === 'ar'
          ? 'Praxis is active here. Please close any other open tabs/window'
          : 'Praxis est ouvert ici. Merci de fermer tout autre onglet/page.';

      alert(message);
    }
  }
  if (!document.hidden) {
    if (!multipleTabsOpen) {
      await configureLogger();

      self.worker = new Worker(new URL('./App.bg.js', import.meta.url));

      window.addEventListener(APP_EVENTS.ONLINE, () => {
        sendMessage(APP_EVENTS.ONLINE);
      });

      window.addEventListener(APP_EVENTS.OFFLINE, () => {
        sendMessage(APP_EVENTS.OFFLINE);
      });
      bodyEl.classList.remove('multipleTabsContainer');
      self.localStorage.setItem('clientId', clientId);
    } else {
      bodyEl.classList.add('multipleTabsContainer');
      document.body.style.backgroundImage = 'none';
    }
    if (event.data.type === APP_EVENTS.LOAD_APP) {
      document.getElementById('loadPraxis').style.display = 'none';
    }
    ReactDOM.render(
      <BrowserRouter>
        <App multipleTabsAreOpen={multipleTabsOpen} />
      </BrowserRouter>,
      document.getElementById('praxisRoot')
    );
  }
});
