/* eslint-disable no-console */
import includes from 'lodash/includes';
import { each } from 'lodash';
import some from 'lodash/some';
import last from 'lodash/last';
import {
  addListener,
  bindEventListener,
  clearAlarm,
  createAlarm,
  sendMessage,
} from '../utils/pwa.utils';
import properties from '../conf/properties';
import { getPraxisUid } from '../repositories/system.setting.repository';
import { getCurrentUsersPreferences } from '../repositories/user.preference.repository';
import { APP_EVENTS, ROLE } from '../constants/Constants';
import Logger from '../utils/reactLogger.util';
import hustleUtil from '../utils/hustle.util';

const onlineEventHandlers = [];
const offlineEventHandlers = [];
let isHalted;
let isDhisOnline;
let timeoutEventReferenceCount;

export const dhisOnline = (callBack) => {
  if (!includes(onlineEventHandlers, callBack))
    onlineEventHandlers.push(callBack);
};

export const isOnline = () => isDhisOnline;

export const dhisOffline = (callBack) => {
  if (!includes(offlineEventHandlers, callBack))
    offlineEventHandlers.push(callBack);
};

export const onTimeoutOccurred = () => {
  if (timeoutEventReferenceCount === undefined) timeoutEventReferenceCount = 0;
  timeoutEventReferenceCount += 1;
  const timeoutDelay = properties.http.timeout + 5000;
  setTimeout(() => {
    timeoutEventReferenceCount -= 1;
  }, timeoutDelay);
};

export const dhisConnectivityCheck = () => {
  const hasRoles = (allowedRoles, userRoles) => {
    if (userRoles === undefined) return false;

    return some(userRoles, (userAuth) => includes(allowedRoles, userAuth.name));
  };

  const sendHeadRequest = async (url) => {
    try {
      await hustleUtil.checkHustleQueueCount();
      await fetch(url, { mode: 'no-cors' });
      isDhisOnline = true;
      Logger.info('DHIS is accessible');
      sendMessage(APP_EVENTS.DHIS_ONLINE);
    } catch {
      Logger.info('DHIS is not accessible');
      isDhisOnline = false;
      sendMessage(APP_EVENTS.DHIS_OFFLINE);
    }
  };

  const buildUrl = async (userPreferences) => {
    let getRequestParams = '';
    if (userPreferences && userPreferences.selectedProject) {
      if (
        hasRoles([ROLE.COORDINATION_LEVEL_APPROVER], userPreferences.userRoles)
      ) {
        let countryName;
        if (userPreferences.selectedProject.parent)
          countryName = userPreferences.selectedProject.parent.name;
        getRequestParams = `&ctry=${countryName}`;
      } else {
        const projCode = last(
          userPreferences.selectedProject.name.split(' - ')
        );
        getRequestParams = `&prj=${projCode}`;
      }
    }
    const praxisVersion = properties.praxis.version;
    const praxisUid = await getPraxisUid();
    return `${
      properties.dhisPing.url
    }?${new Date().getTime()}&pv=${praxisVersion}&pid=${praxisUid}&${
      properties.praxis.platform
    }${getRequestParams}`;
  };
  return getCurrentUsersPreferences().then(buildUrl).then(sendHeadRequest);
};

export const checkNow = () => dhisConnectivityCheck();

const createAlarms = () => {
  Logger.info('Registering dhis monitor alarm');
  createAlarm('dhisConnectivityCheckAlarm', {
    periodInMinutes: properties.dhisPing.retryIntervalInMinutes,
  });
};

export const dhisStart = () => {
  if (isHalted) return false;
  createAlarms();
  return dhisConnectivityCheck();
};

export const dhisResume = () => {
  isHalted = false;
  return dhisStart();
};

export const dhisStop = () => {
  if (isHalted) return;
  clearAlarm('dhisConnectivityCheckAlarm');
  isDhisOnline = false;
  sendMessage(APP_EVENTS.DHIS_OFFLINE);
};

export const dhisHalt = () => {
  dhisStop();
  isHalted = true;
};

export const hasPoorConnectivity = () =>
  timeoutEventReferenceCount !== undefined && timeoutEventReferenceCount > 0;

const onDhisOnline = () => {
  each(onlineEventHandlers, (handler) => {
    handler.call({});
  });
};

const onDhisOffline = () => {
  each(offlineEventHandlers, (handler) => {
    handler.call({});
  });
};
bindEventListener();
addListener(APP_EVENTS.DHIS_OFFLINE, onDhisOffline);
addListener(APP_EVENTS.DHIS_ONLINE, onDhisOnline);
