/* eslint-disable no-param-reassign */
import moment from 'moment';
import _ from 'lodash';

export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_WEEK_FORMAT_W = 'GGGG[W]W';
export const ISO_WEEK_FORMAT_WW = 'GGGG[W]WW';
export const MAX_DATE_AND_YEAR = '9999-12-31';

export const getFormattedPeriod = (period) =>
  moment(period, ISO_WEEK_FORMAT_W).format(ISO_WEEK_FORMAT_WW);

export const maxDate = (dateStrings) => {
  const epochs = (d) => moment(d).valueOf();
  const max = _.maxBy(dateStrings, epochs);
  return moment(max);
};

export const subtractAndGetFirstDayOfNextISOWeek = (numberOfWeeks) =>
  moment(moment().endOf('isoWeek') + 1)
    .subtract(numberOfWeeks, 'week')
    .format(ISO_8601_DATE_FORMAT);

export const toISODate = (date) =>
  moment.utc(date).format(ISO_8601_DATE_FORMAT);

const getPeriodRange = (type, total, options) => {
  options = options || {};
  const difference = options.excludeCurrent ? total : total - 1;

  let format;
  if (type === 'week') format = ISO_WEEK_FORMAT_WW;
  if (type === 'month') format = 'YYYYMM';
  if (type === 'year') format = 'YYYY';

  return _.times(total, (index) =>
    moment()
      .subtract(difference - index, type)
      .format(format)
  );
};

export const getPeriodRangeInYears = (yearsToDisplay) =>
  getPeriodRange('year', yearsToDisplay);

export const getPeriodRangeInWeeks = (weeksToDisplay, options) =>
  getPeriodRange('week', weeksToDisplay, options);

export const getPeriodRangeInMonths = (monthsToDisplay, options) =>
  getPeriodRange('month', monthsToDisplay, options);

export const getNumberOfISOWeeksInMonth = (month) => {
  const monthMoment = moment(month, 'YYYYMM');
  const daysInMonth = _.range(1, monthMoment.daysInMonth() + 1);

  let numberOfMondays = _.reduce(
    daysInMonth,
    (mondays, day) => mondays + (monthMoment.date(day).day() === 1 ? 1 : 0),
    0
  );

  const addLastMonthMondayToCurrentMonth =
    monthMoment.date(1).day() === 2 ||
    monthMoment.date(1).day() === 3 ||
    monthMoment.date(1).day() === 4;
  const removeMondayFromCurrentMonth =
    monthMoment.date(monthMoment.daysInMonth()).day() !== 0 &&
    monthMoment.date(monthMoment.daysInMonth()).day() < 4;

  if (addLastMonthMondayToCurrentMonth) {
    numberOfMondays += 1;
  }

  if (removeMondayFromCurrentMonth) {
    numberOfMondays -= 1;
  }
  return numberOfMondays;
};

export const getOlderDateTime = (dateTimeA, dateTimeB) => {
  if (dateTimeA === undefined) {
    return dateTimeB;
  }
  if (dateTimeB === undefined) {
    return dateTimeA;
  }
  return moment(dateTimeA).isBefore(dateTimeB) ? dateTimeA : dateTimeB;
};

export const toDhisFormat = (m) => m.format('GGGG[W]WW');

export const subtractWeeks = (numberOfWeeks) =>
  moment().subtract(numberOfWeeks, 'week').format('YYYY-MM-DD');

export default {
  getFormattedPeriod,
  maxDate,
  getPeriodRangeInWeeks,
  getPeriodRangeInMonths,
  subtractAndGetFirstDayOfNextISOWeek,
  toISODate,
  getNumberOfISOWeeksInMonth,
  getOlderDateTime,
  toDhisFormat,
  subtractWeeks,
};
