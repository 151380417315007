import React, { useContext } from 'react';

const UserContext = React.createContext({
  currentUser: {
    isLoggedIn: false,
  },
  updateCurrentUser: () => {},
});
export default UserContext;

export const useUserContext = () => useContext(UserContext);
