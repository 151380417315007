/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */

import { APP_EVENTS } from '../constants/Constants';

/* eslint-disable no-console */
let serviceWorkerRegistration;
export const clientId = new Date().getTime();
let receivedClientId;
const registerMessageCallback = (messageType, callback) => (event) => {
  if (event.data.type === messageType) callback(event.data.data);
};

const addListener = (type, callback) => {
  navigator.serviceWorker.addEventListener(
    'message',
    registerMessageCallback(type, callback)
  );
};
const sendMessage = (type, data) => {
  serviceWorkerRegistration.active.postMessage({ type, data });
};
const channel = new BroadcastChannel(APP_EVENTS.MULTIPLE_TABS);

const loadApp = () => {
  channel.postMessage({
    type: APP_EVENTS.LOAD_APP,
  });
};

const checkForMultipleClients = (registration) => {
  serviceWorkerRegistration = registration;
  if (serviceWorkerRegistration.active) {
    sendMessage('checkForMultipleClients', clientId);
    return new Promise((resolve, reject) => {
      addListener('checkForMultipleClients', (data) => {
        receivedClientId = data.clientId;
        if (!data.multipleTabsAreOpen && data.clientId === clientId) {
          channel.postMessage({
            type: 'multipleTabs',
            multipleTabsAreOpen: false,
            clientId: data.clientId,
          });
          addListener('focusActiveTab', () => {
            channel.postMessage({
              type: 'focusActiveTab',
              clientId: data.clientId,
              multipleTabsAreOpen: true,
            });
          });
          resolve(registration);
        } else {
          reject('multipleTabsAreOpen');
        }
      });
    });
  }
  return registration;
};

const serviceWorkerStateObserver = (registration) => {
  const serviceWorker = registration.installing;
  if (serviceWorker && !serviceWorker.onstatechange) {
    serviceWorker.onstatechange = () => {
      switch (serviceWorker.state) {
        case 'installed':
          if (navigator.serviceWorker.controller) {
            console.log('New or updated content is available.');
          } else {
            console.log('Content is now available offline!');
            loadApp();
          }
          break;
        default:
          break;
      }
    };
  }
};

export default function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('../../service.worker.js')
      .then(checkForMultipleClients)
      .then((registration) => {
        serviceWorkerStateObserver(registration);
        console.info('SW registered: ', registration);
      })
      .catch((registrationError) => {
        if (registrationError === 'multipleTabsAreOpen') {
          channel.postMessage({
            multipleTabsAreOpen: true,
            clientId: receivedClientId,
          });
        } else {
          console.error('SW registration failed: ', registrationError);
        }
      });
  }
}
