/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useLocaleContext } from '../../context/localeContext';
import { getTranslatedMessage } from '../../../services/translation.service';
import useEscapeKey from '../hooks/useEscapeKey';

const DescriptionPopup = ({
  modalTitle,
  confirmationMessage,
  onClose,
  zIndex,
  multipleItemDescriptions,
}) => {
  const { layoutDirection } = useLocaleContext();
  const nodeRef = useRef();
  const intl = useIntl();

  const handleOutsideClick = (e) => {
    if (nodeRef.current !== null && !nodeRef.current.contains(e.target)) {
      onClose(e);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);
    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  useEscapeKey(onClose);

  return (
    <div>
      <div
        className="description-popup modal-backdrop fade in"
        style={{ zIndex: 1040 + ((zIndex && 1) || 0) + zIndex * 10 }}
      />
      <div
        className="modal fade in show"
        style={{ zIndex: 1050 + zIndex * 10 }}
      >
        <div
          ref={(node) => {
            nodeRef.current = node;
          }}
          className={`modal-dialog modal-lg ${layoutDirection}`}
        >
          <div className="modal-content">
            <div className="popupDialog">
              <div className="modal-header">
                <button
                  onClick={onClose}
                  type="button"
                  className="close close-label-to-left"
                  data-dismiss="modal"
                  data-testid="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h4>{modalTitle}</h4>
              </div>
              <div className="modal-body">
                {multipleItemDescriptions ? (
                  <>
                    {Object.values(confirmationMessage)[0].map((value, id) => (
                      <span key={id}>
                        <b>{value.title}</b>
                        {id === Object.values(confirmationMessage)[0].length - 1
                          ? ': '
                          : ', '}
                      </span>
                    ))}
                    {Object.keys(confirmationMessage)[0]}
                  </>
                ) : (
                  <>
                    {/* <p>{confirmationMessage}</p>
                    {isEmpty(confirmationMessage) && (
                      <FormattedMessage
                        id="noDescriptionLabel"
                        defaultMessage="No description available"
                      />
                    )} */}
                    <p>
                      {isEmpty(confirmationMessage)
                        ? getTranslatedMessage(
                            intl,
                            'noDescriptionLabel',
                            'No description available'
                          )
                        : confirmationMessage}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DescriptionPopup.defaultProps = {
  zIndex: 0,
  multipleItemDescriptions: undefined,
};

DescriptionPopup.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
  multipleItemDescriptions: PropTypes.any,
};

export default DescriptionPopup;
