import React from 'react';
import PropTypes from 'prop-types';
import './Loading.less';

const Loading = ({ loading, message }) =>
  loading ? (
    <div className="container loading-container">
      <div className="loading">
        <div className="spinner">
          <img src="../../../img/ajax-loader.gif" alt="Loading" />
          <span>{message}</span>
        </div>
      </div>
    </div>
  ) : null;

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default Loading;
