import hustleUtil from '../utils/hustle.util';
import { HUSTLE_DATA_TYPE } from '../constants/Constants';

const publishDataToHustle = async (type, locale) => {
  await hustleUtil.publishToHustle(
    {
      type,
      data: [],
      locale,
    },
    'dataValues',
    true
  );
};

export const publishDownloadProjectData = async (locale) => {
  await publishDataToHustle(
    HUSTLE_DATA_TYPE.DOWNLOAD_MODULE_DATA_FOR_PROJECT,
    locale
  );
  await publishDataToHustle(
    HUSTLE_DATA_TYPE.DOWNLOAD_REPORT_DEFINITIONS,
    locale
  );
  await publishDataToHustle(HUSTLE_DATA_TYPE.DOWNLOAD_REPORT_DATA, locale);
  await publishDataToHustle(HUSTLE_DATA_TYPE.DOWNLOAD_HISTORICAL_DATA, locale);
};

export const publishDownloadMetaData = async (locale) => {
  await publishDataToHustle(HUSTLE_DATA_TYPE.DOWNLOAD_METADATA, locale);
};

export default {
  publishDownloadProjectData,
  publishDownloadMetaData,
};
