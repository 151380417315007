/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import { createZip } from '../../utils/zip.utils';
import ConfirmDialog from '../../shared/components/confirm-dialog/ConfirmDialog';
import { getTranslatedMessage } from '../../services/translation.service';
import backupEntireDB from '../../utils/indexed.backup.db.util';
import mergedProperties from '../../conf/properties';
import { useLocaleContext } from '../../shared/context/localeContext';
import { useLoadingContext } from '../../shared/context/loadingContext';

const ExportApplication = React.forwardRef((props, ref) => {
  const [showNotification, setShowNotification] = useState(false);
  const intl = useIntl();
  const notificationMessages = useRef(null);
  const { locale } = useLocaleContext();
  const { showLoading, hideLoading, setLoadingMessage } = useLoadingContext();

  const errorCallBack = (error, errorMessage) => {
    hideLoading();
    if (_.isObject(error)) {
      notificationMessages.current = {
        notificationMessage: intl.formatMessage(
          { id: errorMessage },
          { error: error.message }
        ),
        notificationTitle: getTranslatedMessage(
          intl,
          'errorNotification',
          'Error'
        ),
      };
    }
  };

  const handleExportApplication = async () => {
    showLoading();
    try {
      const data = await backupEntireDB();
      const file = await createZip(
        'praxis_idb',
        'praxis_idb_',
        '.clone',
        `.${mergedProperties.praxis.fileExtension}`,
        data
      );
      await saveAs(file.zipFileData, file.zipFileName);
      hideLoading();
    } catch (error) {
      errorCallBack(error, 'createCloneErrorMessage');
      setShowNotification(true);
    }
  };

  useEffect(() => {
    setLoadingMessage(
      getTranslatedMessage(intl, 'pleaseWaitLabel', 'Please wait...')
    );
  }, []);

  useImperativeHandle(ref, () => ({
    handleExportApplication,
  }));

  return (
    showNotification && (
      <ConfirmDialog
        data-testId="confirmDialog"
        modalTitle={notificationMessages.current.notificationTitle}
        confirmationMessage={notificationMessages.current.notificationMessage}
        onOk={() => setShowNotification(false)}
        okLabel="okLabel"
        locale={locale}
        showCancelBtn={false}
        onCancel={() => setShowNotification(false)}
      />
    )
  );
});

ExportApplication.displayName = 'ExportApplication';
export default ExportApplication;
