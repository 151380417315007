/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useImperativeHandle } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { getTranslatedMessage } from '../../services/translation.service';
import ConfirmDialog from '../../shared/components/confirm-dialog/ConfirmDialog';
import properties from '../../conf/properties';
import { readFile } from '../../pwa/pwa.filesystem.service';
import { readZipFile } from '../../utils/zip.utils';
import { restore } from '../../dbutils/indexeddb.utils';
import { logout } from '../../helpers/session.helper';
import { useLocaleContext } from '../../shared/context/localeContext';
import { useUserContext } from '../../shared/context/userContext';
import { useLoadingContext } from '../../shared/context/loadingContext';

const ImportApplication = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({});
  const hiddenFileInput = React.useRef(null);
  const { locale } = useLocaleContext();
  const { currentUser, updateCurrentUser } = useUserContext();
  const { showLoading, hideLoading, setLoadingMessage } = useLoadingContext();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const arrayBufferToString = (buffer) => {
    const bufView = new Uint16Array(buffer);
    const { length } = bufView;
    let result = '';
    for (let i = 0; i < length; i += 65535) {
      let addition = 65535;
      if (i + 65535 > length) {
        addition = length - i;
      }
      result += String.fromCharCode.apply(
        null,
        bufView.subarray(i, i + addition)
      );
    }
    return result;
  };

  const errorCallBack = (error) => {
    hideLoading();
    if (_.isObject(error)) {
      setNotification({
        notificationMessage: intl.formatMessage(
          { id: 'loadCloneErrorMessage' },
          { error: error.message }
        ),
        notificationTitle: getTranslatedMessage(
          intl,
          'errorNotification',
          'Error'
        ),
      });
      setShowNotification(true);
    }
  };

  const successCallback = async (fileData) => {
    setTimeout(async () => {
      let zipFiles;
      try {
        zipFiles = await readZipFile(fileData.target.result);
      } catch (error) {
        errorCallBack({
          message: getTranslatedMessage(
            intl,
            'errorInZipFile',
            'Error in reading the file'
          ),
        });
        return;
      }

      const result = {};
      for (const key of Object.keys(zipFiles)) {
        if (_.endsWith(zipFiles[key].name, '.clone')) {
          const arrayBuffer = await zipFiles[key].async('ArrayBuffer');
          const content = JSON.parse(
            arrayBufferToString(new Uint8Array(arrayBuffer))
          );
          _.merge(result, content);
        }
      }

      await restore(result);
      try {
        updateCurrentUser({ ...currentUser, isLoggedIn: false });
        await logout(currentUser);
      } catch (error) {
        errorCallBack(error);
      }
      hideLoading();
    }, 1000);
  };

  const onImportFileSelect = async (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      showLoading();
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (properties.praxis.fileExtension !== fileExtension) {
        errorCallBack({
          message: getTranslatedMessage(
            intl,
            'errorInZipFile',
            'The file could not be accessed.'
          ),
        });
        return;
      }
      try {
        const fileData = await readFile(file);
        await successCallback(fileData);
      } catch (error) {
        errorCallBack(error);
      }
    }
  };

  useEffect(() => {
    hiddenFileInput.current.value = '';
  }, [showNotification]);

  useEffect(() => {
    setLoadingMessage(
      getTranslatedMessage(intl, 'pleaseWaitLabel', 'Please wait...')
    );
  }, []);

  useImperativeHandle(ref, () => ({
    handleClick,
  }));

  return (
    <>
      <input
        accept={`.${properties.praxis.fileExtension}`}
        type="file"
        ref={hiddenFileInput}
        onChange={onImportFileSelect}
        style={{ display: 'none' }}
        data-testid="test-import"
      />
      {showNotification && (
        <ConfirmDialog
          modalTitle={notification.notificationTitle}
          confirmationMessage={notification.notificationMessage}
          onOk={() => setShowNotification(false)}
          okLabel="okLabel"
          locale={locale}
          showCancelBtn={false}
          onCancel={() => setShowNotification(false)}
        />
      )}
    </>
  );
});

ImportApplication.displayName = 'ImportApplication';
export default ImportApplication;
