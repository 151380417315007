/* eslint-disable no-param-reassign */
import * as _ from 'lodash';
import md5 from 'js-md5';

const get = (name) => {
  if (!name) return undefined;
  name = name.toLowerCase() + _.random(0, 9999999999) + new Date().getTime();
  return `a${md5(name).substring(0, 10)}`;
};

export default {
  get,
};
