/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { NAVIGATION_ROUTES } from '../constants/Constants';
import { useUserContext } from './context/userContext';
import { hasRoles } from '../utils/helper.utils';

const PrivateRoute = ({ children, roles }) => {
  const { currentUser } = useUserContext();
  const location = useLocation();
  if (!currentUser.isLoggedIn) {
    return (
      <Navigate
        to={`/${NAVIGATION_ROUTES.LOGIN}`}
        state={{ from: location }}
        replace
      />
    );
  }

  if (hasRoles(roles, currentUser)) {
    return children;
  }
  return <Navigate to="/" replace />;
};

PrivateRoute.propTypes = {
  children: PropTypes.any.isRequired,
  roles: PropTypes.array.isRequired,
};

export default PrivateRoute;
