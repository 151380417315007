import { each, endsWith, isEmpty, startsWith } from 'lodash';
import moment from 'moment';
import { getPraxisdDbInstance } from '../indexedDb';
import { promisifyValue } from '../utils/helper.utils';

const CHANGE_LOG_STORE_NAME = 'changeLog';

export const getChangeLog = async (type) => {
  const indexedDBInstance = await getPraxisdDbInstance();
  const changeLog = await indexedDBInstance
    .table(CHANGE_LOG_STORE_NAME)
    .get(type);
  return changeLog === undefined ? changeLog : changeLog.lastUpdatedTime;
};

export const getChangeLogByType = async (type) => {
  const indexedDb = await getPraxisdDbInstance();
  const dataValues = await indexedDb
    .table(CHANGE_LOG_STORE_NAME)
    .where('type')
    .equals(type)
    .toArray();
  if (!isEmpty(dataValues)) {
    return dataValues[0] === undefined
      ? dataValues[0]
      : dataValues[0].lastUpdatedTime;
  }
  return undefined;
};

export const upsertTypeAndLastUpdatedTime = async (type, lastUpdateInTime) => {
  const indexedDb = await getPraxisdDbInstance();
  const lastUpdatedTime = lastUpdateInTime || moment().toISOString();
  await indexedDb.table(CHANGE_LOG_STORE_NAME).put({ type, lastUpdatedTime });
  return { type, lastUpdatedTime };
};

export const clearChangeLog = async (type) => {
  const indexedDb = await getPraxisdDbInstance();
  const allChangeLogs = await indexedDb.table(CHANGE_LOG_STORE_NAME).toArray();
  const deletePromises = [];
  each(allChangeLogs, (changeLog) => {
    if (endsWith(type, ':') && startsWith(changeLog.type, type))
      deletePromises.push(
        indexedDb.table(CHANGE_LOG_STORE_NAME).delete(changeLog.type)
      );
    if (!endsWith(type, ':') && changeLog.type === type)
      deletePromises.push(
        indexedDb.table(CHANGE_LOG_STORE_NAME).delete(changeLog.type)
      );
  });

  return promisifyValue(deletePromises);
};

export default {
  getChangeLog,
  getChangeLogByType,
  upsertTypeAndLastUpdatedTime,
  clearChangeLog,
};
