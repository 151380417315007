/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppRoutes from './shared/AppRoutes';
import AppHeader from './shared/components/header/Header';
import AppContextProvider from './shared/context/AppContextProvider';
import MultipleTabs from './MultipleTabs';
import AppFooter from './shared/components/footer/AppFooter';
import hustleUtil from './utils/hustle.util';

const App = ({ multipleTabsAreOpen }) => {
  useEffect(async () => {
    if (!multipleTabsAreOpen) {
      document.body.style.backgroundImage = 'url(/img/bg.png)';
      await hustleUtil.checkHustleQueueCount();
    }
  }, []);

  return (
    <>
      {!multipleTabsAreOpen && (
        <AppContextProvider>
          <AppHeader />
          <main className="container main ">
            <AppRoutes />
          </main>
          <AppFooter />
        </AppContextProvider>
      )}
      {multipleTabsAreOpen && <MultipleTabs data-testid="multipleTabs" />}
    </>
  );
};

App.propTypes = {
  multipleTabsAreOpen: PropTypes.bool,
};

App.defaultProps = {
  multipleTabsAreOpen: false,
};
export default App;
