import { isEqual } from 'lodash';
import { prodURL } from './properties';

const localUrl = typeof window !== 'undefined' ? window.location.origin : '';
const productKeyDetails = {
  authHeader: '',
  dhisUrl: '',
  productKeyLevel: '',
  allowedOrgUnits: '',
  isKeyGeneratedFromProd: isEqual(prodURL, localUrl),
};

export default productKeyDetails;
