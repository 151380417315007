/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import Dexie from 'dexie';
import { map } from 'lodash';
import platformConfig from './pwa.properties';
import { getLogDbInstance, getPraxisdDbInstance } from '../indexedDb';
import { getHustleInstance } from './hustle.util';
import { clearStorage } from '../services/storage.service';

const messageListeners = {};
const alarms = {};
const alarmListeners = {};

export const executeEventListener = (message) => {
  if (messageListeners[message.name] instanceof Function) {
    messageListeners[message.name].call({}, message.data);
  }
};

export const sendMessage = (messageName, data) => {
  const payload = {
    name: messageName,
    data,
  };
  executeEventListener(payload);
  if (self.worker) {
    self.worker.postMessage(payload);
  } else {
    self.postMessage(payload);
  }
};

export const createNotification = (title, message, callback) => {
  const requestPermission =
    (Notification.requestPermission && Notification.requestPermission()) ||
    Promise.resolve('granted');
  requestPermission.then((permission) => {
    if (permission === 'granted') {
      const options = {
        icon: `${self.basePath}img/logo.png`,
        body: message,
      };
      const notification = new Notification(title, options);
      notification.addEventListener('click', () => {
        if (callback) callback();
        notification.close();
      });
    } else if (permission !== 'denied') {
      createNotification(title, message);
    }
  });
};

export const addListener = (message, callback) => {
  messageListeners[message] = callback;
};

const messageEventHandler = (event) => {
  executeEventListener(event.data);
};

export const bindEventListener = () => {
  self.addEventListener('message', messageEventHandler);
};

export const uninstall = async () => {
  const clearCacheStorage = async () => {
    const keys = await window.caches.keys();
    return map(keys, (key) => window.caches.delete(key));
  };
  const unregisterServiceWorker = async () => {
    const registrations = await navigator.serviceWorker.getRegistrations();
    return map(registrations, (registration) => registration.unregister());
  };
  const terminateWebWorker = () => {
    if (self.worker) {
      self.worker.terminate();
    }
  };
  const clearIndexedDB = async () => {
    const deleteIDBDatabase = async (databaseName) => {
      if (databaseName !== 'hustle') {
        try {
          await Dexie.delete(databaseName);
          console.log(`${databaseName} successfully deleted`);
        } catch (error) {
          console.log(`Could not delete ${databaseName}`, error);
        }
      } else {
        console.log(`${databaseName} successfully deleted`);
      }
    };
    const closeAllDatabaseConnections = async (databaseName) => {
      if (databaseName !== 'hustle') {
        try {
          let db;
          if (databaseName === 'praxis') {
            db = await getPraxisdDbInstance();
          } else if (databaseName === 'praxisLogs') {
            db = await getLogDbInstance();
          }
          db.close();
        } catch (error) {
          console.log('error:', error);
        }
      } else {
        const hustleInstance = await getHustleInstance();
        hustleInstance.wipe();
      }
    };
    const databaseNames = [
      platformConfig.praxis.dbName,
      platformConfig.praxis.dbForLogs,
      platformConfig.praxis.dbForHustle,
    ];
    for (const databaseName of databaseNames) {
      await closeAllDatabaseConnections(databaseName);
      await deleteIDBDatabase(databaseName);
    }
  };

  await clearCacheStorage();
  await unregisterServiceWorker();
  terminateWebWorker();
  await clearIndexedDB();
  clearStorage();
};

const createAlarmObject = (name, duration) => {
  let interval;

  const stop = () => {
    clearInterval(interval);
    interval = null;
  };

  const executeListeners = () => {
    const listeners = alarmListeners[name] || [];
    listeners.forEach((listener) => {
      listener.call({});
    });
  };

  const start = () => {
    if (!interval) {
      interval = setInterval(executeListeners, duration);
    }
  };

  return {
    start,
    stop,
  };
};

export const createAlarm = (alarmName, options) => {
  const durationInMilliseconds = options.periodInMinutes * 60 * 1000;
  alarms[alarmName] =
    alarms[alarmName] || createAlarmObject(alarmName, durationInMilliseconds);
  alarms[alarmName].start();
};

export const addAlarmListener = (alarmName, callback) => {
  alarmListeners[alarmName] = alarmListeners[alarmName] || [];
  alarmListeners[alarmName].push(callback);
};

export const clearAlarm = (alarmName) => {
  if (alarms[alarmName]) {
    alarms[alarmName].stop();
  }
};
