/* eslint-disable no-param-reassign */
import sjcl from 'sjcl';
import moment from 'moment';

const generateHashedKey = (key) => {
  key += moment().format('YYYY-MM-DD');

  const bitArray = sjcl.hash.sha256.hash(key);
  return sjcl.codec.hex.fromBits(bitArray);
};

export const setItemInStorage = (key, value) => {
  const hashedKey = generateHashedKey(key);

  const encryptedValue = sjcl.encrypt(hashedKey, JSON.stringify(value));
  window.sessionStorage.setItem(hashedKey, btoa(encryptedValue));
};

export const getItemFromStorage = (key) => {
  const hashedKey = generateHashedKey(key);
  try {
    return JSON.parse(
      sjcl.decrypt(hashedKey, atob(window.sessionStorage.getItem(hashedKey)))
    );
  } catch (e) {
    return null;
  }
};

export const clearStorage = () => {
  window.sessionStorage.clear();
};

export const removeItemFromStorage = (key) => {
  const hashedKey = generateHashedKey(key);
  window.sessionStorage.removeItem(hashedKey);
};
