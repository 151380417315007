/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
import { map } from 'lodash';
import moment from 'moment';
import { getLogDbInstance } from '../indexedDb';

const LOG_STORE_NAME = 'logs';

const putLog = async (level, ...args) => {
  const parseArgsForMessages = map(args, (arg) => arg);
  const logObject = {
    method: level,
    datetime: moment().toISOString(),
    message: parseArgsForMessages,
  };
  const indexedDB = await getLogDbInstance();
  try {
    await indexedDB.table(LOG_STORE_NAME).put(logObject);
  } catch (e) {
    console.error('Could not save log entry to indexedDB', e, args[0]);
  }
};

const Logger = {
  info: (...args) => {
    putLog('info', ...args);
    console.info(...args);
  },
  warn: (...args) => {
    putLog('warn', ...args);
    console.warn(...args);
  },
  error: (...args) => {
    putLog('error', ...args);
    console.error(...args);
  },
};

export default Logger;
