/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import platformConfig from '../pwa/pwa.properties';
import hustleUtil, { getHustleInstance } from '../utils/hustle.util';
import { getPraxisdDbInstance } from '../indexedDb';

const PRAXIS_DB = platformConfig.praxis.dbName;
const HUSTLE_DB = platformConfig.praxis.dbForHustle;
let INDEX_DB = PRAXIS_DB;

const storesToSkipSpecificData = {
  systemSettings: {
    keyPathValues: ['productKey', 'praxisUid'],
    keyPath: 'key',
  },
};

const getDbInstance = () => {
  const dbLookUp = {
    [HUSTLE_DB]: getHustleInstance,
    [PRAXIS_DB]: getPraxisdDbInstance,
  };
  return dbLookUp[INDEX_DB]();
};

const switchDB = (dbName) => {
  INDEX_DB = dbName;
};

const getAllStoreNames = async () => {
  const indexedDb = await getDbInstance();
  return indexedDb.tables.map((table) => table.name);
};

const truncate = async (storeNames) => {
  const indexedDb = await getDbInstance();

  const truncatePromises = _.map(storeNames, (name) => {
    if (!_.includes(_.keys(storesToSkipSpecificData), name)) {
      return indexedDb.table(name).clear();
    }
  });
  return Promise.all(truncatePromises);
};

const decodeBase64 = (data) => decodeURIComponent(escape(atob(data)));

const restore = async (backupData) => {
  const restoreDB = async (data, shouldRestoreHustle = false) => {
    const storeNames = _.keys(data);
    if (shouldRestoreHustle) {
      _.map(storeNames, (name) => {
        hustleUtil.wipe(name);
      });
      _.map(storeNames, async (name) =>
        hustleUtil.addItemToHustle(_.flatten(data[name]), name)
      );
    } else {
      const insertAll = async () => {
        const indexedDb = await getDbInstance();
        const insertPromises = _.map(storeNames, (name) =>
          indexedDb.table(name).bulkPut(_.flatten(data[name]))
        );
        return Promise.all(insertPromises);
      };

      return truncate(storeNames).then(insertAll);
    }
  };

  const decodeData = (data) => {
    const result = {};
    _.each(data, (val, key) => {
      result[key] = decodeBase64(val);
    });
    return result;
  };

  const restorePraxis = (data) => {
    const parseBackupDataByStore = (backUpData, existingStoreNames) => {
      const backupDataByStore = {};
      _.each(backUpData, (val, key) => {
        if (key !== 'hustle') {
          const store = key.split('__')[1];
          const index = key.split('__')[2];
          if (_.includes(existingStoreNames, store)) {
            if (_.isEmpty(backupDataByStore[store])) {
              backupDataByStore[store] = [];
            }
            backupDataByStore[store][index] = JSON.parse(val);
          }
        }
      });
      return backupDataByStore;
    };
    return getAllStoreNames()
      .then(_.partial(parseBackupDataByStore, data))
      .then(restoreDB);
  };

  const restoreHustle = (data) => {
    switchDB(HUSTLE_DB);
    return restoreDB(JSON.parse(data), true);
  };

  const decodedBackupData = decodeData(backupData);
  await restorePraxis(decodedBackupData);
  await restoreHustle(decodedBackupData.hustle);
  switchDB(PRAXIS_DB);
};

export { restore };
