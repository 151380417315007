/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import UserContext from './userContext';
import ProductKeyContext from './productKeyContext';
import LocaleContext from './localeContext';
import {
  getUserLocale,
  loadProductKey,
  updateUserLocale,
} from '../../repositories/system.setting.repository';
import * as translationService from '../../services/translation.service';
import LoadingContext from './loadingContext';

const AppContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [locale, setLocale] = useState('en');
  const [layoutDirection, setLayoutDirection] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [productKeyDetails, setProductKeyDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const getMessages = async () => {
    const translations = await translationService.getMessages(locale);
    setMessages(translations);
  };

  const updateLocale = async (selectedLocale) => {
    setLocale(selectedLocale);
    setLayoutDirection(selectedLocale === 'ar' ? 'rtl' : '');
    await updateUserLocale(selectedLocale);
  };

  const updateProductKeyDetails = async () => {
    const response = await loadProductKey();
    setProductKeyDetails(response);
  };

  const updateCurrentUser = (updatedUser) => {
    setCurrentUser((prevState) => ({
      ...prevState,
      ...updatedUser,
    }));
  };

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const setLoadingMessage = (msg) => {
    setMessage(msg);
  };

  const currentUserValue = useMemo(
    () => ({
      currentUser,
      updateCurrentUser,
    }),
    [currentUser]
  );

  const productKeyDetailsValue = useMemo(
    () => ({
      productKeyDetails,
      updateProductKeyDetails,
    }),
    [productKeyDetails]
  );

  const localeValue = useMemo(
    () => ({
      locale,
      layoutDirection,
      updateLocale,
    }),
    [locale, layoutDirection]
  );

  const loadingValue = useMemo(
    () => ({
      loading,
      message,
      showLoading,
      hideLoading,
      setLoadingMessage,
    }),
    [loading]
  );

  const getLocale = async () => {
    const userLocale = await getUserLocale();
    setLayoutDirection(userLocale === 'ar' ? 'rtl' : '');
    setLocale(userLocale);
    await updateUserLocale(userLocale);
  };

  useEffect(() => {
    updateProductKeyDetails();
  }, []);

  useEffect(() => {
    getMessages();
    getLocale();
  }, [locale]);

  return (
    <LoadingContext.Provider value={loadingValue}>
      <UserContext.Provider value={currentUserValue}>
        <ProductKeyContext.Provider value={productKeyDetailsValue}>
          <LocaleContext.Provider value={localeValue}>
            <IntlProvider locale={locale} messages={messages}>
              <div className={layoutDirection}>{children}</div>
            </IntlProvider>
          </LocaleContext.Provider>
        </ProductKeyContext.Provider>
      </UserContext.Provider>
    </LoadingContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AppContextProvider;
