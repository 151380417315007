/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import Dexie from 'dexie';
import properties from './conf/properties';
import allObjectStores from './migrator/createMigrations';
import updateScripts from './migrator/updateMigrations';
import Logger from './utils/reactLogger.util';

let praxisDb;
let logDb;

const LOG_STORE_NAME = 'logs';
export const createDb = () => {
  const { praxis } = properties;
  logDb = new Dexie(praxis.dbForLogs);
  logDb.version(1).stores({
    [LOG_STORE_NAME]: '++, method, datetime',
  });

  logDb
    .open()
    .then(() => {
      Logger.info('Log DB Ready...');
    })
    .catch((error) => {
      Logger.error('Failed to load Log DB: ', error);
    });

  const objectStoresLength = allObjectStores.length;
  const indexesLength = allObjectStores.reduce(
    (acc, cur) => acc + Object.values(cur)[0].split(',').length,
    0
  );
  let praxisDbVersion =
    objectStoresLength + indexesLength + updateScripts.length + 10;
  praxisDb = new Dexie(praxis.dbName, praxisDbVersion);

  let dexieStoreFormat = {};
  allObjectStores.forEach((store) => {
    dexieStoreFormat = { ...dexieStoreFormat, ...store };
  });

  praxisDb.version(praxisDbVersion).stores(dexieStoreFormat);

  praxisDb
    .version(++praxisDbVersion)
    .stores({
      tempTrackedEntityInstances: 'trackedEntity,[orgUnit+caseCode]',
      trackedEntityInstances: 'trackedEntityInstance,[orgUnit+caseCode]',
    })
    .upgrade((trans) => {
      trans
        .table('trackedEntityInstances')
        .toCollection()
        .each((trackedEntityInstance) => {
          const tempTrackedEntityInstance = { ...trackedEntityInstance };
          tempTrackedEntityInstance.trackedEntity =
            trackedEntityInstance.trackedEntityInstance;
          trackedEntityInstance.lastUpdated &&
            (tempTrackedEntityInstance.updatedAt =
              trackedEntityInstance.lastUpdated); // not present in  unsubmitted events
          trackedEntityInstance.created &&
            (tempTrackedEntityInstance.createdAt =
              trackedEntityInstance.created); // not present in  unsubmitted events
          delete tempTrackedEntityInstance.trackedEntityInstance;
          delete tempTrackedEntityInstance.lastUpdated;
          delete tempTrackedEntityInstance.created;
          trans
            .table('tempTrackedEntityInstances')
            .add(tempTrackedEntityInstance);
        });
    });

  praxisDb.version(++praxisDbVersion).stores({
    trackedEntityInstances: null,
  });

  praxisDb
    .version(++praxisDbVersion)
    .stores({
      trackedEntityInstances: 'trackedEntity,[orgUnit+caseCode]',
    })
    .upgrade((trans) => {
      trans
        .table('tempTrackedEntityInstances')
        .toArray()
        .then((objs) => trans.table('trackedEntityInstances').bulkAdd(objs));
    });

  praxisDb.version(++praxisDbVersion).stores({
    tempTrackedEntityInstances: null,
  });

  praxisDb
    .version(++praxisDbVersion)
    .stores({
      enrollments: 'enrollment,[program+orgUnit+status],trackedEntity',
    })
    .upgrade((trans) =>
      trans
        .table('enrollments')
        .toCollection()
        .modify((enrollment) => {
          enrollment.trackedEntity = enrollment.trackedEntityInstance;
          enrollment.enrolledAt = enrollment.enrollmentDate;
          enrollment.occurredAt = enrollment.incidentDate;
          enrollment.lastUpdated &&
            (enrollment.updatedAt = enrollment.lastUpdated); // not present in  unsubmitted events
          enrollment.created && (enrollment.createdAt = enrollment.created); // not present in  unsubmitted events
          delete enrollment.trackedEntityInstance;
          delete enrollment.enrollmentDate;
          delete enrollment.incidentDate;
          delete enrollment.lastUpdated;
          delete enrollment.created;
        })
    );

  praxisDb
    .version(++praxisDbVersion)
    .stores({
      programEvents:
        'event,[program+orgUnit+period],[program+orgUnit+localStatus],eventCode,occurredAt,orgUnit,period,enrollment',
    })
    .upgrade((trans) =>
      trans
        .table('programEvents')
        .toCollection()
        .modify((programEvent) => {
          programEvent.occurredAt = programEvent.eventDate;
          programEvent.lastUpdated &&
            (programEvent.updatedAt = programEvent.lastUpdated); // not present in  unsubmitted events
          programEvent.created &&
            (programEvent.createdAt = programEvent.created); // not present in  unsubmitted events
          programEvent.trackedEntityInstance &&
            (programEvent.trackedEntity = programEvent.trackedEntityInstance);
          programEvent.dueDate &&
            (programEvent.scheduledAt = programEvent.dueDate); // not present in  unsubmitted events
          programEvent.createdByUserInfo &&
            (programEvent.createdBy = programEvent.createdByUserInfo); // not present in  unsubmitted events
          programEvent.lastUpdatedByUserInfo &&
            (programEvent.updatedBy = programEvent.lastUpdatedByUserInfo); // not present in  unsubmitted events
          delete programEvent.trackedEntityInstance;
          delete programEvent.eventDate;
          delete programEvent.lastUpdated;
          delete programEvent.created;
          delete programEvent.dueDate;
          delete programEvent.createdByUserInfo;
          delete programEvent.lastUpdatedByUserInfo;
        })
    );

  praxisDb
    .open()
    .then(() => {
      Logger.info('Praxis DB Ready...');
    })
    .catch((error) => {
      Logger.error('Failed to load Praxis DB: ', error);
    });
  updateScripts.forEach((script) => {
    const objectStore = Object.keys(script)[0];
    praxisDb.table(objectStore).put(script[objectStore]);
  });
};

let praxisDbInstance;
let logDbInstance;

export const getLogDbInstance = async () => {
  if (logDbInstance === undefined) {
    logDbInstance = await logDb.open();
  }
  return logDbInstance;
};

export const getPraxisdDbInstance = async () => {
  if (praxisDbInstance === undefined) {
    praxisDbInstance = await praxisDb.open();
  }
  return praxisDbInstance;
};

export default {
  createDb,
  getLogDbInstance,
  getPraxisdDbInstance,
};
