/* eslint-disable no-return-await */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { isUndefined, isEmpty, isNaN } from 'lodash';
import properties from '../conf/properties';
import { getPraxisdDbInstance } from '../indexedDb';
import cipherUtils from '../utils/cipher.utils';
import { promisifyValue, rejectPromisifyValue } from '../utils/helper.utils';
import dhisId from '../utils/dhis.id';
import productKeyDetails from '../conf/product.key.details';

const SYSTEM_SETTINGS_STORE_NAME = 'systemSettings';

export const getSystemSettings = async (key) => {
  const indexedDb = await getPraxisdDbInstance();
  const setting = await indexedDb
    .table(SYSTEM_SETTINGS_STORE_NAME)
    .where({
      key,
    })
    .toArray();
  return setting.length > 0 ? setting[0].value : rejectPromisifyValue(null);
};

export const upsert = async (systemSettings) => {
  const indexedDb = await getPraxisdDbInstance();
  const settingData = await indexedDb
    .table(SYSTEM_SETTINGS_STORE_NAME)
    .bulkPut(systemSettings);
  return settingData;
};

export const getPraxisUid = async () => {
  const praxisUidKey = 'praxisUid';
  const createPraxisUid = () => {
    const uId = dhisId.get('b1gstr1ngWhichHa5Prax1sUid');
    return upsert([{ key: praxisUidKey, value: uId }]).then(
      (setting) => setting.value
    );
  };

  const returnPraxisUid = (uId) => uId;

  return getSystemSettings(praxisUidKey).then(returnPraxisUid, createPraxisUid);
};

export const getUserLocale = async () => {
  const indexedDb = await getPraxisdDbInstance();
  const locale = await indexedDb
    .table(SYSTEM_SETTINGS_STORE_NAME)
    .get('locale');
  return locale ? locale.value : 'en';
};

export const updateUserLocale = async (locale) => {
  const localeJson = {
    key: 'locale',
    value: locale,
  };
  const indexedDb = await getPraxisdDbInstance();
  await indexedDb.table(SYSTEM_SETTINGS_STORE_NAME).put(localeJson);
};

export const getProductKeyLevel = () => {
  const { devMode } = properties;
  const { dhis } = properties;
  if (
    devMode &&
    (isUndefined(productKeyDetails.productKeyLevel) ||
      isEmpty(productKeyDetails.productKeyLevel))
  )
    productKeyDetails.productKeyLevel = dhis.productKeyLevel;
  return productKeyDetails.productKeyLevel;
};

const decryptProductKey = (productKey) => cipherUtils.decrypt(productKey);

const cacheProductKeyDetails = (productKey) => {
  const decryptedProductKey = JSON.parse(decryptProductKey(productKey));
  const details = {
    authHeader: decryptedProductKey.data.authHeader,
    dhisUrl: decryptedProductKey.data.dhisUrl,
    productKeyLevel: decryptedProductKey.data.productKeyLevel,
    allowedOrgUnits: decryptedProductKey.data.allowedOrgUnits,
    isKeyGeneratedFromProd: decryptedProductKey.keyGeneratedFromProd,
  };
  productKeyDetails.authHeader = details.authHeader;
  productKeyDetails.dhisUrl = details.dhisUrl;
  productKeyDetails.productKeyLevel = details.productKeyLevel;
  productKeyDetails.allowedOrgUnits = details.allowedOrgUnits;
  productKeyDetails.isKeyGeneratedFromProd = details.isKeyGeneratedFromProd;
  return details;
};

export const getProductKey = async (key) => {
  const indexedDBInstance = await getPraxisdDbInstance();
  const setting = await indexedDBInstance
    .table(SYSTEM_SETTINGS_STORE_NAME)
    .get(key);
  return setting ? setting.value : undefined;
};

export const loadProductKey = async () => {
  if (properties.devMode) {
    return promisifyValue();
  }
  const productKey = await getProductKey('productKey');
  if (productKey) {
    return cacheProductKeyDetails(productKey);
  }
  return {};
};

export const isProductKeySet = async () => {
  if (properties.devMode) {
    return promisifyValue(true);
  }
  const productKey = await getProductKey('productKey');
  return productKey !== undefined;
};

export const getAllowedOrgUnits = () => {
  if (
    properties.devMode &&
    (isUndefined(productKeyDetails.allowedOrgUnits) ||
      isEmpty(productKeyDetails.allowedOrgUnits))
  )
    return undefined;

  return productKeyDetails.allowedOrgUnits;
};

export const isSyncOff = async () =>
  await getSystemSettings('offline').then(
    (isOffline) => isOffline,
    () => false
  );

export const upsertSyncSetting = async (syncState) => {
  const syncSetting = { key: 'offline', value: syncState };
  const indexedDBInstance = await getPraxisdDbInstance();
  await indexedDBInstance.table(SYSTEM_SETTINGS_STORE_NAME).put(syncSetting);
};

export const getAuthHeader = () => {
  if (
    properties.devMode &&
    (isUndefined(productKeyDetails.authHeader) ||
      isEmpty(productKeyDetails.authHeader))
  ) {
    productKeyDetails.authHeader = properties.dhis.authHeader;
  }
  return productKeyDetails.authHeader;
};

export const getNotificationSettingValue = () =>
  getSystemSettings('notificationSettingValue').then(
    (standardDeviationValue) =>
      isNaN(parseFloat(standardDeviationValue))
        ? 1.25
        : parseFloat(standardDeviationValue),
    () => 1.25
  );

export const upsertProductKey = async (productKeyJson) => {
  try {
    cacheProductKeyDetails(productKeyJson.value);
    const indexedDb = await getPraxisdDbInstance();
    await indexedDb.table(SYSTEM_SETTINGS_STORE_NAME).put(productKeyJson);
    return productKeyJson;
  } catch (e) {
    return null;
  }
};

export const getDhisUrl = () => {
  if (
    properties.devMode &&
    (isUndefined(productKeyDetails.dhisUrl) ||
      isEmpty(productKeyDetails.dhisUrl))
  )
    return properties.dhis.url;

  return productKeyDetails.dhisUrl;
};

export const isKeyGeneratedFromProd = () =>
  productKeyDetails.isKeyGeneratedFromProd || false;
