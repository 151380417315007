import { getPraxisdDbInstance } from '../indexedDb';

const CUSTOM_ATTRIBUTES_STORE_NAME = 'attributes';

const getAllCustomAttributes = async () => {
  const indexedDb = await getPraxisdDbInstance();
  const rows = await indexedDb.table(CUSTOM_ATTRIBUTES_STORE_NAME).toArray();

  return rows;
};

export default getAllCustomAttributes;
